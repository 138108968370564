import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const generateBillDesktopQrDetails = (data) => {

    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, 'shaft/api/payment-gateway/generate-billdesk-qr-details/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default generateBillDesktopQrDetails;