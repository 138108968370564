import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getIntentUrl = (data) => {
 
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, 'shaft/api/payment-gateway/get-intent-url/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getIntentUrl