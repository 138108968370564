import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import commonFunctionObject from "../../common-function";
import DataMapping from "../../context/data-mapping";
import { STOREDATA } from "../../context/data-mapping/action/action.type";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import LogoutServiceObject from "../../services/LogoutService";
import Modal from "../Modal";
import "./QualitricsPopUp.scss";
import PropTypes from 'prop-types';
import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import Button from "../../global/Button";
import RadiantBorder from "../RadiantBorder";

const QualitricsPopUp = ({ logOutApiCall }) => {
    const [contextData, dispathData] = useContext(DataMapping)
    const location = useLocation()
    const [themeState] = useContext(ThemeContext);
    const navigate = useNavigate()
    const { analytics } = useContext(AnalyticsContextProvider);
    const [data, dispatchData] = useContext(DataMapping);
    const isWealthSystemActive = data?.currentActiveData?.system?.toLowerCase() === "wealth";


    const qualitricsApiCall = () => {
        var accountNumber = contextData?.currentActiveData?.accountNumber;
        var currentUserData = contextData?.parsedUserData[accountNumber];
        let productName = contextData?.currentActiveData?.system === "las" ? "LAS" : contextData?.currentActiveData?.system === "ccod" ? "CCOD" : contextData?.currentActiveData?.system === "wealth" ? "WEALTH" : commonFunctionObject.getProductName(currentUserData);
        let analytics_data = {
            user: {
                loginMethod: "",
                loginStatus: "",
                gcid: contextData?.getUserDataV3?.body?.PRIMARY_GCID,
                loginType: "",
                loanaccountNo: "",
                firstloginDate: ""
            },
            products: {
                productCode: productName,
            },
            data: {
                ctaText: "Click here",
                eventName: "click here to rate experience",
                entityType: "",
                componentName: "QualitricsPopUp",
                offerId: "",
                bannerTitle: "",
                menuTitle: "",
                faqTitle: "",
                searchTerm: "",
                lobActivity: "",
                status: "",
            }
        }


        var userData = contextData.getUserDataV3.body
        var qualObj = npsData(contextData.currentActiveData, userData)
        var reqObj = {
            "header": {
                "authToken": contextData.authToken
            },
            "body": {
                "userId": userData.USER_ID ? userData.USER_ID : "",
                "lan": qualObj.lan,
                "entity": qualObj.entity, /*entity is nothing but product family of contract. TCHFL or CFAB */
                "accountStatus": qualObj.accountStatus, /*If close account then closed*/
                "customerName": qualObj.customerName,
                "emailId": qualObj.emailId,
                "mobileNo": qualObj.mobileNo,
                "sourceSection": "logout",
                "source": "Web",
                "primaryGcid": contextData.getUserDataV3.body.PRIMARY_GCID,
                "productName": qualObj.productName,
                "chatBotCustomerDetails": contextData?.encryptedUserDetails
            }
        }
        analytics_data.data.entityType = qualObj.entity;
        analytics.trackData("clicktorateExperience", analytics_data);
        dispathData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })

        LogoutServiceObject.generateSurveyService(reqObj).then((encryptedId) => {
            dispathData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            window.open(`${window.location.origin}/partner/int/survey-portal/#/qualitrics-form?encryptedData=${encryptedId}`, "_self")

        }).catch((error) => {
            dispathData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            logOutApiCall();

        })

    }

    const npsData = (currentActiveData, userData) => {
        let currentActiveParsedData = contextData?.parsedUserData[currentActiveData.accountNumber]
        var userEmail = "";
        var userMobile = "";
        if (userData.emails) {
            userEmail = commonFunctionObject.getLastestFieldValFromField(userData.emails);
        }
        if (userData.mobiles) {
            userMobile = commonFunctionObject.getLastestFieldValFromField(userData.mobiles);
        }

        let requestObj;

        if (currentActiveData.system.toLowerCase() === "retail") {
            requestObj = {
                "lan": currentActiveData.accountNumber,
                "entity": commonFunctionObject.getProductFamily(currentActiveParsedData).toLowerCase() === "tchfl" ? "TCHFL" : "TCL",
                "accountStatus": commonFunctionObject.checkActiveStatus(currentActiveParsedData.retailAccountDetails),
                "customerName": userData.FIRST_NAME + " " + userData.LAST_NAME,
                "emailId": userEmail ? userEmail : '',
                "mobileNo": userMobile ? userMobile : '',
                "sourceType": "Web",
                "productName": commonFunctionObject.getProductName(currentActiveParsedData)
            }
        } else if (currentActiveData.system.toLowerCase() === "ccod") {
            requestObj = {
                "lan": currentActiveParsedData.sapContract.CUSTOMER_NO,
                "entity": currentActiveParsedData.cdiContract.COMPANY,
                "accountStatus": currentActiveParsedData.cdiContract.STATUS,
                "customerName": (currentActiveParsedData.cdiContract.CUSTOMER_NAME) ? currentActiveParsedData.cdiContract.CUSTOMER_NAME : "",
                "emailId": currentActiveParsedData.cdiContract.EMAIL ? currentActiveParsedData.cdiContract.EMAIL : '',
                "mobileNo": currentActiveParsedData.cdiContract.MOBILE ? currentActiveParsedData.cdiContract.MOBILE : '',
                "sourceType": "Web",
                "productName": "CCOD"
            }
        } else if (currentActiveData.system.toLowerCase() === "wealth" || currentActiveData.system.toLowerCase() === "las") {
            if (data.openContracts) {
                let retailAccountNumber = Object.keys(data.openContracts)[0]
                currentActiveParsedData = contextData?.parsedUserData[retailAccountNumber]
                requestObj = {
                    "lan": retailAccountNumber,
                    "entity": commonFunctionObject.getProductFamily(currentActiveParsedData).toLowerCase() === "tchfl" ? "TCHFL" : "TCL",
                    "accountStatus": commonFunctionObject.checkActiveStatus(currentActiveParsedData.retailAccountDetails),
                    "customerName": userData.FIRST_NAME + " " + userData.LAST_NAME,
                    "emailId": userEmail ? userEmail : '',
                    "mobileNo": userMobile ? userMobile : '',
                    "sourceType": "Web",
                    "productName": commonFunctionObject.getProductName(currentActiveParsedData)
                }
                return requestObj
            } else if (data.ccodContracts) {
                let ccodAccountNumber = Object.keys(data.ccodContracts)[0]
                currentActiveParsedData = contextData?.parsedUserData[ccodAccountNumber]
                requestObj = {
                    "lan": currentActiveParsedData.sapContract.CUSTOMER_NO,
                    "entity": currentActiveParsedData.cdiContract.COMPANY,
                    "accountStatus": currentActiveParsedData.cdiContract.STATUS,
                    "customerName": (currentActiveParsedData.cdiContract.CUSTOMER_NAME) ? currentActiveParsedData.cdiContract.CUSTOMER_NAME : "",
                    "emailId": currentActiveParsedData.cdiContract.EMAIL ? currentActiveParsedData.cdiContract.EMAIL : '',
                    "mobileNo": currentActiveParsedData.cdiContract.MOBILE ? currentActiveParsedData.cdiContract.MOBILE : '',
                    "sourceType": "Web",
                    "productName": "CCOD"
                }
                return requestObj
            } else if (data.closeContracts) {
                let closedAccountNumber = Object.keys(data.closeContracts)[0]
                if (data?.parsedUserData[closedAccountNumber].system.toLowerCase() === "retail") {
                    currentActiveParsedData = contextData?.parsedUserData[closedAccountNumber]
                    requestObj = {
                        "lan": closedAccountNumber,
                        "entity": commonFunctionObject.getProductFamily(currentActiveParsedData).toLowerCase() === "tchfl" ? "TCHFL" : "TCL",
                        "accountStatus": commonFunctionObject.checkActiveStatus(currentActiveParsedData.retailAccountDetails),
                        "customerName":  userData.FIRST_NAME + " " + userData.LAST_NAME,
                        "emailId": userEmail ? userEmail : '',
                        "mobileNo": userMobile ? userMobile : '',
                        "sourceType": "Web",
                        "productName": commonFunctionObject.getProductName(currentActiveParsedData)
                    }
                    return requestObj
                } else if (data?.parsedUserData[closedAccountNumber].system.toLowerCase() === "ccod") {
                    currentActiveParsedData = contextData?.parsedUserData[closedAccountNumber]
                    requestObj = {
                        "lan": currentActiveParsedData.sapContract.CUSTOMER_NO,
                        "entity": currentActiveParsedData.cdiContract.COMPANY,
                        "accountStatus": currentActiveParsedData.cdiContract.STATUS,
                        "customerName": (currentActiveParsedData.cdiContract.CUSTOMER_NAME) ? currentActiveParsedData.cdiContract.CUSTOMER_NAME : "",
                        "emailId": currentActiveParsedData.cdiContract.EMAIL ? currentActiveParsedData.cdiContract.EMAIL : '',
                        "mobileNo": currentActiveParsedData.cdiContract.MOBILE ? currentActiveParsedData.cdiContract.MOBILE : '',
                        "sourceType": "Web",
                        "productName": "CCOD"
                    }
                    return requestObj
                }

            } else {
                requestObj = {
                    "lan": "",
                    "entity": '',
                    "accountStatus": "",
                    "customerName":  userData.FIRST_NAME + " " + userData.LAST_NAME,
                    "emailId": userEmail ? userEmail : '',
                    "mobileNo": userMobile ? userMobile : '',
                    "sourceType": "Web",
                    "productName": ""

                }
                return requestObj
            }

        }

        return requestObj;

    }

    return (
        <>
            <Modal>
                <div className='qualitrics-modal-popup' onClick={qualitricsApiCall}>
                    <RadiantBorder>
                        <div className='qualitrics-popup-border' style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#fff"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}  >
                            <div className='close-btn'>
                                <img onClick={(e) => { e.stopPropagation(); logOutApiCall() }} src={themeState === "THEME_DARK" ? "assets/images/crossImg.svg" : "assets/images/qualtrics-close-icon.svg"} alt="close" />
                            </div>
                            <div className="rate-experience-container">
                                <div className='rate-experience'>
                                    <p
                                        style={{
                                            color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                        }}
                                    >
                                        We value your opinion.!
                                    </p>
                                </div>
                                <div className='qualtrics-description'>
                                    <p
                                        style={{
                                            color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                        }}
                                    >
                                        Take a moment to let us know how  we're doing
                                        {/* Hello, we would love to hear from you! <span className="clickHereQual" onClick={qualitricsApiCall}>Click here</span> to rate your Web Experience */}
                                    </p>
                                </div>
                                <div className="experience-emojis">
                                    <img src={"assets/images/neutral.gif"} alt="neutral-emoji" />
                                    <img src={"assets/images/good.gif"} alt="good-emoji" />
                                    <img src={"assets/images/great.gif"} alt="great-emoji" />

                                </div>
                                <div className={isWealthSystemActive ? "qualtrics-button wealth-qualtrics-button " : "qualtrics-button"}>
                                    <Button>Click Here</Button>
                                </div>
                            </div>
                        </div>
                    </RadiantBorder>
                </div>
            </Modal>
        </>
    )
}

QualitricsPopUp.propTypes = {
    logOutApiCall: PropTypes.func,

}

export default QualitricsPopUp