import React, { useContext, useEffect, useState } from 'react';
import "./QrScan.scss";
import RadiantBorder from '../../global/RadiantBorder';
import Modal from '../../global/Modal';
import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import { STOREDATA } from '../../context/data-mapping/action/action.type';
import DataMapping from '../../context/data-mapping';
import { useTimer } from 'react-timer-hook';

const QrScan = ({qrBase64,  initialTime, setPaymentFailureContent ,setPaymentFailurePopup, closeQr, fetchPaymentGatewayDetails, countDown=false}) =>{
    const [themeState] = useContext(ThemeContext);
    const [showTimer , setShowTimer] = useState(false);
    const [data , dispatchData] = useContext(DataMapping)
    const expiryTimestamp = new Date();
    expiryTimestamp.setMinutes(expiryTimestamp.getMinutes() + 5); // Add 5 minutes
    if(countDown){
        expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 0); // Add 30 seconds
    }else{
        expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 30); // Add 30 seconds
    }

    const { seconds, minutes, isRunning, restart } = useTimer({
        expiryTimestamp,
        onExpire: () => handleTimerExpiration(), 
    });

  const handleTimerExpiration = () => {
    handlePaymentFailure();
  };

  const handlePaymentFailure = () => {
    fetchPaymentGatewayDetails();
    if (data?.qrDetails !== "" || data?.intentDetails !== "") {
      dispatchData({
        name: "qrDetails",
        payload: "",
        type: STOREDATA,
      });
      dispatchData({
        name: "intentDetails",
        payload: "",
        type: STOREDATA,
      });
      setPaymentFailureContent(
        "Your transaction could not be processed. Please check your bank account before initiating a new transaction"
      );
      setPaymentFailurePopup(true);
      closeQr();
    }
  };

  const formatTime = (timeInSeconds) => {
    if (timeInSeconds > 0) {
      if (timeInSeconds % 30 === 0 && timeInSeconds < 271) {
        fetchPaymentGatewayDetails();

        const formattedMinutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
        const formattedSeconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
      } else {
        const formattedMinutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
        const formattedSeconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
      }
    } else {
      handlePaymentFailure();
    }
  };

  useEffect(() =>{
    if (minutes === 5 && seconds === 0) {
        setShowTimer(true);
      }
  },[minutes === 5 && seconds === 0])

  const remainingTimeInSeconds = minutes * 60 + seconds;
  const formattedTime = formatTime(remainingTimeInSeconds);

    return (
        <>
        <Modal style={{ background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""}`, }}>
            <RadiantBorder className="qrContainer">
                {
                    countDown ?
                    <div style={{ background: `${themeState === "THEME_DARK" ? "rgb(34, 35, 46)" : ""}`, borderRadius: "3rem",color:`${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}>
                        <p className='paymentMessage'>Your payment is being processed. Please check your bank account for confirmation before attempting another transaction</p>
                        <p>Time Remaining  :  {formattedTime}</p>
                    </div>
                    :
                    <div style={{ background: `${themeState === "THEME_DARK" ? "rgb(34, 35, 46)" : ""}`, borderRadius: "3rem",color:`${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}>
                        <p className='qrScanMessage'>Please scan below QR via any UPI apps to make payment of Rs.{qrBase64[1]}</p>
                        <img className='qrImage' src={`data:image/jpeg;base64,${qrBase64[0]}`} alt="qr image" style={{filter: themeState === "THEME_DARK" ? "invert(1)" : "none" , }}/>
                        {
                            showTimer &&
                            <>
                                <p> Time Remaining </p>
                                <p>{formattedTime}</p>
                            </>
                        }
                    </div>
                }
            </RadiantBorder>
        </Modal>
        </>
    )
}

export default QrScan;