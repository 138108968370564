import React, { useContext, useEffect, useRef, useState } from "react";
import "./PaymentPopup.scss";
import RadiantBorder from "../../global/RadiantBorder";
import Modal from "../../global/Modal";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import Input from "../../global/Input";
import Radio from "../Forms/Radio";
import Button from "../../global/Button";
import AccountNumberDropDown from "../../global/AccountNumberDropDown/AccountNumberDropDown";
import DataMapping from "../../context/data-mapping";
import commonFunctionObject from "../../common-function";
import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import { STOREDATA } from "../../context/data-mapping/action/action.type";
import OverviewServiceObject from "../../services/Overview/OverviewService";
import apiConfigObj from "../../app-config/app-config";

const PaymentPopup = ({setPaymentPopup , paymentDetails , setPaymentDetails , payoverdueApicall, callPayOnline, setQrBase64,setQrPopupDisplay, setOkButtonPopUpFlag, setOkButtonPopUpContent, setFailurePopUpModal , setFailurePopUpContent}) =>{
    const [themeState] = useContext(ThemeContext);
    const [data, dispatchData] = useContext(DataMapping);
    const [accountNumberArray, setAccountNumberArray] = useState(data?.accountNumberDropDownArray);
    const [optionState, setOptionState] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [ paymentMethod , setPaymentMethod] = useState("upi");
    const [overdueCharges , setOverdueCharges] = useState(Math.floor(Number(paymentDetails?.PgsData?.odCharges)));
    const [overdueChargesError , setOverdueChargesError] = useState();
    const [overdueAmount , setOverdueAmount] = useState(Math.floor(Number(paymentDetails?.PgsData?.odAmount)));
    const [overdueAmountError, setOverdueAmountError] = useState();
    const [totalAmount , setTotalAmount] = useState();
    const [outstandingAmount , setOutstandingAmount] = useState(Math.floor(Number(paymentDetails?.PgsData?.odAmount)));
    const [outstandingAmountError ,setOutstandingAmountError] = useState()
    const [iosDevice , setIosDevice] = useState(false);
    const [deviceType , setDeviceType] = useState();
    const [buttonDisabled , setButtonDisabled] = useState(false);
    const[emailError , setEmailError] = useState();
    const [mobileError , setMobileError] = useState();
    const[alternateEmailId , setAlternateEmailId] = useState();
    const [alternateMobileNumber , setAlternateMobileNumber] = useState();
    const initialRender = useRef(false);
    useEffect(()=>{
        createAccountNumberDropDown()
        if(accountNumberArray["LAS"]){
            delete accountNumberArray["LAS"]
        }
        if(accountNumberArray["WEALTH"]){
            delete accountNumberArray["WEALTH"]
        }
        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            setIosDevice(true);
            setPaymentMethod("other");

        } else{
            document.getElementById("upi").checked = true;
        }
        commonFunctionObject.getDeviceAndBrowserInfo().then((deviceInfo)=>{
            setDeviceType(deviceInfo.deviceType)
        }).catch((error)=>{
            console.log(error);
        })



    },[])
    useEffect(()=>{
        setAlternateEmailId("");
        setAlternateMobileNumber("");
        setEmailError("");
        setMobileError("");
        if (!initialRender.current) {
            initialRender.current = true
            return
        } else {
            payoverdueApicall();
        }
        
        
    },[data.currentActiveData.accountNumber])
    useEffect(() => {
        if (paymentDetails?.PgsData) {
            if(data.currentActiveData.system.toLowerCase() === "retail"){

                const odCharges = Math.floor(Number(paymentDetails?.PgsData?.odCharges || 0));
                const odAmount = Math.floor(Number(paymentDetails?.PgsData?.odAmount || 0));
    
                setOverdueCharges(odCharges);
                setOverdueAmount(odAmount);
                setTotalAmount(odCharges + odAmount);
            }else{
                setOutstandingAmount(Math.floor(paymentDetails?.PgsData?.odAmount))
            }
        }
    }, [paymentDetails]);

    const createAccountNumberDropDown = () => {
        let accountNumber = data?.currentActiveData.accountNumber
        let productName = data?.currentActiveData.system === "retail" ? commonFunctionObject.getProductName(data.parsedUserData[accountNumber]) : ""
        let shortName = data?.currentActiveData.system === "retail" ? commonFunctionObject.getProductShortName(productName) : data?.currentActiveData.system === "ccod" ? "HTL" : "LAS"
        setSelectedOption(accountNumber + " (" + shortName + ")");
        // initialRender.current = false;
    }
     
    const radioCheck= (event , type) =>{
        setPaymentMethod(type);
    }

    const storeSelectAccNumberData= () =>{
        dispatchData({
            name: "paymentDropdownChange",
            payload: true,
            type: STOREDATA
        })
        if(overdueChargesError){
            setOverdueChargesError("")
        }
        if(overdueAmountError){
            setOverdueAmountError("")
        }
        if(!iosDevice){
            document.getElementById("upi").checked = true;
        }
    }

    const handleChargesChange = (number) =>{
        let numberRegex =  /^\d+$/;
        let value = number === "" ?  0 : parseFloat(number.replace(/,/g, ''))
        if(numberRegex.test(value) && value <= Math.floor(Number(paymentDetails?.PgsData?.odCharges)) && value+Number(overdueAmount) > 0){
            setOverdueCharges(value);
            setTotalAmount(Number(value) + Number(overdueAmount))
            setOverdueChargesError("")

            if(value+Number(overdueAmount) > 0 &&  overdueAmount <= Math.floor(Number(paymentDetails?.PgsData?.odAmount))){
                if(overdueAmountError){
                    setOverdueAmountError("")
                }
            }
        }else{
            if(value+Number(overdueAmount) <= 0){
                setOverdueChargesError(`Overdue Charges can not be less than Rs.1 and more than Rs.${Math.floor(Number(paymentDetails?.PgsData?.odCharges))}`);
                setOverdueAmountError(`Overdue Amount can not be less than Rs.1 and more than Rs${Math.floor(Number(paymentDetails?.PgsData?.odAmount))}`);
                setOverdueCharges(value);
                setTotalAmount(Number(value) + Number(overdueAmount))
            }else{
                setOverdueCharges(value);
                setTotalAmount(Number(value) + Number(overdueAmount))
                setOverdueChargesError(`Overdue Charges can not be less than Rs.1 and more than Rs.${Math.floor(Number(paymentDetails?.PgsData?.odCharges))}`);
            }
        }
    }

    const handleAmountChange = (number) =>{
        let numberRegex = /^\d+(\.\d+)?$/;
        let value = number === "" ? 0 : parseFloat(number.replace(/,/g, ''))
        if(numberRegex.test(value) && value <= Math.floor(Number(paymentDetails?.PgsData?.odAmount)) && value+Number(overdueCharges) > 0){
            setOverdueAmount(value);
            setTotalAmount(Number(value) + Number(overdueCharges))
            setOverdueAmountError("")
            if(value+Number(overdueCharges) > 0 && overdueCharges <= Math.floor(Number(paymentDetails?.PgsData?.odCharges))){
                if(overdueChargesError){
                    setOverdueChargesError("")
                }
            }
        }else{
            if(value+Number(overdueCharges) <= 0){
                if(Math.floor(Number(paymentDetails?.PgsData?.odCharges)) != 0){
                    setOverdueChargesError(`Overdue Charges can not be less than Rs.1 and more than Rs.${Math.floor(Number(paymentDetails?.PgsData?.odCharges))}`);
                }
                setOverdueAmountError(`Overdue Amount can not be less than Rs.1 and more than Rs${Math.floor(Number(paymentDetails?.PgsData?.odAmount))}`);
                setOverdueAmount(value);
                setTotalAmount(Number(value) + Number(overdueCharges))
            }else{
                setOverdueAmount(value);
                setTotalAmount(Number(value) + Number(overdueCharges))
                setOverdueAmountError(`Overdue Amount can not be less than Rs.1 and more than Rs${Math.floor(Number(paymentDetails?.PgsData?.odAmount))}`);
            }
        }
    }

    const handleOutstandingChange = (number) =>{
        let numberRegex = /^\d+(\.\d+)?$/;
        let value = number === "" ? 0 : parseFloat(number.replace(/,/g, ''))
        if(numberRegex.test(value) && value <= Math.floor(Number(paymentDetails?.PgsData?.odAmount)) && value > 9){
            setOutstandingAmount(value);
            setOutstandingAmountError("")
        }else{
            setOutstandingAmount(value);
            setOutstandingAmountError(`Outstanding Amount can not be less than Rs.10 and more than Rs${Math.floor(Number(paymentDetails?.PgsData?.odAmount))}`);
        }
    }

    const handleEmailChange = (value) =>{
        var emailregx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if(value != ""){
            if(!emailregx.test(value)){
                setEmailError("Please Enter A Valid E-Mail Id")
                setAlternateEmailId(value)
            }else{
                setEmailError("")
                setAlternateEmailId(value)
            }
        }else{
            setEmailError("")
            setAlternateEmailId("")
        }
    }

    const handleMobileChange = (value) =>{
        const numberRegex = /^[0-9]*$/;
        if(value != ""){
            if(!numberRegex.test(value)){
                setMobileError("Please Enter A Valid Mobile Number")
                setAlternateMobileNumber(value)
            }else if(value.length != 10){
                setMobileError("Invalid Length")
                setAlternateMobileNumber(value)
            }else{
                setMobileError("")
                setAlternateMobileNumber(value)
            }
        }else{
            setMobileError("")
            setAlternateMobileNumber("")
        }
    }

    const makePayment= () =>{
        setButtonDisabled(true);
            if(data.currentActiveData.system.toLowerCase() === "retail"){
                if(paymentMethod.toLowerCase() === "upi"){
                    if(totalAmount <= 100000){
                        if(deviceType.toLowerCase() === "desktop"){
                            // generate bill desk Qr api call
                            let requestObj = {
                                "header": {
                                    "authToken": data?.authToken,
                                    "systemId" : "1"
                                },
                                "body": {
                                    "customerName": data.basicDetails[data.currentActiveData.accountNumber].FIRSTNAME+ " "+data.basicDetails[data.currentActiveData.accountNumber].LASTNAME,
                                    "contractNo": data.currentActiveData.accountNumber,
                                    "emailId": data.basicDetails[data.currentActiveData.accountNumber].EMAIL,
                                    "mobileNo": data.basicDetails[data.currentActiveData.accountNumber].MOBILENO,
                                    "productName": data.basicDetails[data.currentActiveData.accountNumber].productInfo?.DISPLAY_NAME,
                                    "company": apiConfigObj.appConfig.env === "DEVELOPMENT" ? paymentDetails?.PgsData?.company.toUpperCase() === "TCL" || paymentDetails?.PgsData?.company.toUpperCase() === "TCFSL" ? "TCLV2" : paymentDetails?.PgsData?.company.toUpperCase() + "V2": paymentDetails?.PgsData?.company.toUpperCase() === "TCL" || paymentDetails?.PgsData?.company.toUpperCase() === "TCFSL" ? "TCLMCV2" : paymentDetails?.PgsData?.company.toUpperCase() + "MCV2",
                                    "productCode": paymentDetails?.PgsData?.productCode,
                                    "sourceSystem": paymentDetails?.PgsData?.sourceSystem,                                
                                    "odAmount": overdueAmount,
                                    "odCharges": overdueCharges,
                                    "totalAmount": Math.floor(Number(paymentDetails?.PgsData?.odAmount)) + Math.floor(Number(paymentDetails?.PgsData?.odCharges)),
                                    "paidTotal": totalAmount,
                                    "gcid":data.basicDetails[data.currentActiveData.accountNumber].GCID,
                                    "primaryGcid":data.getUserDataV3.body.PRIMARY_GCID,
                                    "userId":data.getUserDataV3.body.USER_ID,
                                    "alternateMobileNumber":alternateMobileNumber,
                                    "alternateEmailId": alternateEmailId
                                    },
                            }
                            dispatchData({
                                name: "loaderFlag",
                                payload: true,
                                type: STOREDATA,
                            });
                            OverviewServiceObject.billDeskQrService(requestObj).then((response)=>{
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA,
                                });
                                setButtonDisabled(false);
                                    if(response.qr_image){
                                        dispatchData({
                                            name: "qrDetails",
                                            payload: response,
                                            type: STOREDATA,
                                        });
                                        setQrBase64([response.qr_image, totalAmount])
                                        setQrPopupDisplay(true)
                                        setPaymentPopup(false)
                                    }else if(response.ErrorMessage){
                                        setPaymentPopup(false)
                                        setOkButtonPopUpContent(response.ErrorMessage)
                                        setOkButtonPopUpFlag(true)
                                    }
                            }).catch((error)=>{
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA,
                                });
                                setButtonDisabled(false);
                                setFailurePopUpModal(true)
                                setFailurePopUpContent({
                                    apiName: "extract-doc/v1",
                                    message:
                                        "We are experiencing technical difficulty please try after sometime",
                                    errorCode: error,
                                });
                            })
                        }else{
                            
                            // intent api call 
                            let requestObj = {
                                "header": {
                                    "authToken": data?.authToken,
                                    "systemId" : "1"
                                },
                                "body": {
                                    "primaryGcid":data?.getUserDataV3.body.PRIMARY_GCID,
                                    "gcid":data.basicDetails[data.currentActiveData.accountNumber].GCID,
                                    "userId":data?.getUserDataV3.body.USER_ID,                                   
                                    "customerName": data.basicDetails[data.currentActiveData.accountNumber].FIRSTNAME+ " "+data.basicDetails[data.currentActiveData.accountNumber].LASTNAME,
                                    "contractNo": data.currentActiveData.accountNumber,
                                    "emailId": data.basicDetails[data.currentActiveData.accountNumber].EMAIL,
                                    "mobileNo": data.basicDetails[data.currentActiveData.accountNumber].MOBILENO,
                                    "productName": data.basicDetails[data.currentActiveData.accountNumber].productInfo?.DISPLAY_NAME,
                                    "company": apiConfigObj.appConfig.env === "DEVELOPMENT" ? paymentDetails?.PgsData?.company.toUpperCase() === "TCL" || paymentDetails?.PgsData?.company.toUpperCase() === "TCFSL" ? "TCLV2" : paymentDetails?.PgsData?.company.toUpperCase() + "V2": paymentDetails?.PgsData?.company.toUpperCase() === "TCL" || paymentDetails?.PgsData?.company.toUpperCase() === "TCFSL" ? "TCLMCV2" : paymentDetails?.PgsData?.company.toUpperCase() + "MCV2",
                                    "productCode": paymentDetails?.PgsData?.productCode,
                                    "sourceSystem": paymentDetails?.PgsData?.sourceSystem,
                                    "odAmount": overdueAmount,
                                    "odCharges": overdueCharges,
                                    "totalAmount": Math.floor(Number(paymentDetails?.PgsData?.odAmount)) + Math.floor(Number(paymentDetails?.PgsData?.odCharges)),
                                    "paidTotal": totalAmount,
                                    "alternateMobileNumber":alternateMobileNumber,
                                    "alternateEmailId": alternateEmailId
                                }
                            }
                            dispatchData({
                                name: "loaderFlag",
                                payload: true,
                                type: STOREDATA,
                            });
                            OverviewServiceObject.getIntentUrlService(requestObj).then((response) => {
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA,
                                });
                                setButtonDisabled(false);
                                if(response.finalUrl){
                                    window.open(response.finalUrl , "_self");
                                        dispatchData({
                                            name: "intentDetails",
                                            payload: response,
                                            type: STOREDATA,
                                        });
                                }else{
                                    setFailurePopUpModal(true)
                                    setFailurePopUpContent({
                                        apiName: "",
                                        message:"We are experiencing technical difficulty please try after sometime",
                                        errorCode: "406",
                                    });
                                }
                            }).catch((error) => {
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA,
                                });
                                setButtonDisabled(false);
                                setFailurePopUpModal(true)
                                setFailurePopUpContent({
                                    apiName: "",
                                    message:"We are experiencing technical difficulty please try after sometime",
                                    errorCode: error,
                                });
                            }) 
                            
                        }
                    }else{
                        setOkButtonPopUpFlag(true);
                        setOkButtonPopUpContent("The maximum amount that may be paid via UPI is Rs  1 lac")
                        setButtonDisabled(false);
                    }
                }else{
                    // get pgs url
                    let reqObj = {
                        "header": {
                            "authToken": data.authToken
                        },
                        "body": {
                            "customerName": data.basicDetails[data.currentActiveData.accountNumber].FIRSTNAME + " " + data.basicDetails[data.currentActiveData.accountNumber].LASTNAME,
                            "contractNo": data.currentActiveData.accountNumber,
                            "emailId": data.basicDetails[data.currentActiveData.accountNumber].EMAIL,
                            "mobileNo": data.basicDetails[data.currentActiveData.accountNumber].MOBILENO,
                            "totalAmount": totalAmount,
                            "primaryGcid":data.getUserDataV3.body.PRIMARY_GCID,
                            "gcid": data.basicDetails[data.currentActiveData.accountNumber].GCID,
                            "productName": data.basicDetails[data.currentActiveData.accountNumber].productInfo.DISPLAY_NAME,
                            "productCode": paymentDetails?.PgsData?.productCode,
                            "sourceSystem": paymentDetails?.PgsData?.sourceSystem,
                            "odCharges": overdueCharges,
                            "odAmount": overdueAmount,
                            "userId":data.getUserDataV3.body.USER_ID,
                            "company":data.basicDetails[data.currentActiveData.accountNumber].COMPANY.toUpperCase() === "CFAB" ? "TCL" :data.basicDetails[data.currentActiveData.accountNumber].COMPANY.toUpperCase(),
                            "alternateMobileNumber":alternateMobileNumber,
                            "alternateEmailId": alternateEmailId
                        }
                    }
                    dispatchData({
                        name: "loaderFlag",
                        payload: true,
                        type: STOREDATA,
                    });
                    OverviewServiceObject.getPgsUrlService(reqObj).then((response)=>{
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA,
                        });
                        setButtonDisabled(false);
                        if(response.finalUrl){
                            setPaymentPopup(false);
                            dispatchData({
                                name: "pgsUrlGenerated",
                                payload: true,
                                type: STOREDATA,
                            });
                            window.open(response.finalUrl, "_self");
                        }else{
                            setFailurePopUpModal(true)
                            setFailurePopUpContent({
                                apiName: "",
                                message:"We are experiencing technical difficulty please try after sometime",
                                errorCode: "403",
                            });
                        }
                    }).catch((error)=>{
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA,
                        });
                        setButtonDisabled(false);
                        setFailurePopUpModal(true)
                        setFailurePopUpContent({
                            apiName: "",
                            message:"We are experiencing technical difficulty please try after sometime",
                            errorCode: error,
                        });
                    });
                }
            
               
            }else{
                if(paymentMethod.toLowerCase() === "upi"){
                    if(outstandingAmount <= 100000){
                        if(deviceType.toLowerCase() === "desktop"){
                            // generate bill desk Qr api call 
                            let requestObj = {
                                "header": {
                                    "authToken": data?.authToken,
                                    "systemId" : "3"
                                 },
                                "body": {
                                    "customerName": data.basicDetails[data.currentActiveData.accountNumber].sapContract.CUSTOMER_FIRST_NM,
                                    "contractNo": data.currentActiveData.accountNumber,
                                    "emailId": data.basicDetails[data.currentActiveData.accountNumber].cdiContract.EMAIL,
                                    "mobileNo": data.basicDetails[data.currentActiveData.accountNumber].cdiContract.MOBILE,
                                    "productName": "",
                                    "company": apiConfigObj.appConfig.env === "DEVELOPMENT" ? paymentDetails?.PgsData?.company.toUpperCase() === "TCL" || paymentDetails?.PgsData?.company.toUpperCase() === "TCFSL" ? "TCLV2" : paymentDetails?.PgsData?.company.toUpperCase() + "V2": paymentDetails?.PgsData?.company.toUpperCase() === "TCL" || paymentDetails?.PgsData?.company.toUpperCase() === "TCFSL" ? "TCLMCV2" : paymentDetails?.PgsData?.company.toUpperCase() + "MCV2",
                                    "productCode": paymentDetails?.PgsData?.productCode,
                                    "sourceSystem": paymentDetails?.PgsData?.sourceSystem,
                                    "odAmount": outstandingAmount,
                                    "odCharges": "0",
                                    "totalAmount":Math.floor(paymentDetails?.PgsData?.odAmount),
                                    "paidTotal": outstandingAmount,
                                    "gcid":data.basicDetails[data.currentActiveData.accountNumber].GCID,
                                    "primaryGcid":data.getUserDataV3.body.PRIMARY_GCID,
                                    "userId":data.getUserDataV3.body.USER_ID,
                                    "alternateMobileNumber":alternateMobileNumber,
                                    "alternateEmailId": alternateEmailId
                                }
                            }
                            dispatchData({
                                name: "loaderFlag",
                                payload: true,
                                type: STOREDATA,
                            });
                            OverviewServiceObject.billDeskQrService(requestObj).then((response)=>{
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA,
                                });
                                setButtonDisabled(false);
                                    if(response.qr_image){
                                        dispatchData({
                                            name: "qrDetails",
                                            payload: response,
                                            type: STOREDATA,
                                        });
                                        setQrBase64([response.qr_image , outstandingAmount])
                                        setQrPopupDisplay(true)
                                        setPaymentPopup(false)
                                    }else if(response.ErrorMessage){
                                        setPaymentPopup(false)
                                        setOkButtonPopUpContent(response.ErrorMessage)
                                        setOkButtonPopUpFlag(true)
                                    }
                            }).catch((error)=>{
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA,
                                });
                                setButtonDisabled(false);
                                setFailurePopUpModal(true)
                                setFailurePopUpContent({
                                    apiName: "",
                                    message:
                                        "We are experiencing technical difficulty please try after sometime",
                                    errorCode: error,
                                });
                            })
                        }else{
                            //  intent api call
                            let requestObj = {
                                "header": {
                                    "authToken": data?.authToken,
                                    "systemId" : "3"
                                },
                                "body": {
                                    "primaryGcid":data?.getUserDataV3.body.PRIMARY_GCID,
                                    "gcid":data.basicDetails[data.currentActiveData.accountNumber].cdiContract.GCID,
                                    "userId":data?.getUserDataV3.body.USER_ID,                                   
                                    "customerName":data.basicDetails[data.currentActiveData.accountNumber].sapContract.CUSTOMER_FIRST_NM,
                                    "contractNo": data.currentActiveData.accountNumber,
                                    "emailId": data.basicDetails[data.currentActiveData.accountNumber].cdiContract.EMAIL,
                                    "mobileNo": data.basicDetails[data.currentActiveData.accountNumber].cdiContract.MOBILE,
                                    "productName": "null",
                                    "company":apiConfigObj.appConfig.env === "DEVELOPMENT" ? paymentDetails?.PgsData?.company.toUpperCase() === "TCL" || paymentDetails?.PgsData?.company.toUpperCase() === "TCFSL" ? "TCLV2" : paymentDetails?.PgsData?.company.toUpperCase() + "V2": paymentDetails?.PgsData?.company.toUpperCase() === "TCL" || paymentDetails?.PgsData?.company.toUpperCase() === "TCFSL" ? "TCLMCV2" : paymentDetails?.PgsData?.company.toUpperCase() + "MCV2",
                                    "productCode": paymentDetails?.PgsData?.productCode,
                                    "sourceSystem": paymentDetails?.PgsData?.sourceSystem,
                                    "odAmount": outstandingAmount,
                                    "odCharges": "0",
                                    "totalAmount": Math.floor(paymentDetails?.PgsData?.odAmount),
                                    "paidTotal": outstandingAmount,
                                    "alternateMobileNumber":alternateMobileNumber,
                                    "alternateEmailId": alternateEmailId
                                }
                            }
                            dispatchData({
                                name: "loaderFlag",
                                payload: true,
                                type: STOREDATA,
                            });
                            OverviewServiceObject.getIntentUrlService(requestObj).then((response) => {
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA,
                                });
                                setButtonDisabled(false);
                                if(response.finalUrl){
                                    window.open(response.finalUrl , "_self");
                                        dispatchData({
                                            name: "intentDetails",
                                            payload: response,
                                            type: STOREDATA,
                                        });
                                }else{
                                    setFailurePopUpModal(true)
                                    setFailurePopUpContent({
                                        apiName: "",
                                        message:"We are experiencing technical difficulty please try after sometime",
                                        errorCode: "406",
                                    });
                                }
                            }).catch((error) => {
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA,
                                });
                                setButtonDisabled(false);
                                setFailurePopUpModal(true)
                                setFailurePopUpContent({
                                    apiName: "",
                                    message:"We are experiencing technical difficulty please try after sometime",
                                    errorCode: error,
                                });
                            }) 
                        }
                    }else{
                        setOkButtonPopUpFlag(true);
                        setOkButtonPopUpContent("The maximum amount that may be paid via UPI is Rs  1 lac")
                        setButtonDisabled(false);
                    }
                }else{
                    dispatchData({
                        name: "pgsUrlGenerated",
                        payload: true,
                        type: STOREDATA,
                    });
                    setButtonDisabled(false);
                    callPayOnline(outstandingAmount,alternateMobileNumber, alternateEmailId)
                }
            
            }
        
        
    }
    return (
        <>
        <Modal>
            <RadiantBorder className={`paymentContainer ${themeState}`}>
            <img  className="closePayment" onClick={()=>setPaymentPopup(false)} src="assets/images/cancel.svg" alt="cross image" />
               
                <div className="paymentInnerContainer" 
                        style={{
                            background: `${themeState === "THEME_DARK" ? "#22232E" : "#FFFFFF"}`,
                            color:`${themeState === "THEME_DARK" ? "#fff" : "#000"}`,
                            borderRadius: "3rem"
                        }}>
                    <div className="paymentDropdown">
                        <AccountNumberDropDown
                        optionArray={accountNumberArray}
                        optionState={optionState}
                        setOptionState={setOptionState}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        labelFlag={true}
                        labelText="Account Number"
                        className="paymentAccountNumber"
                        storeSelectAccNumberData={storeSelectAccNumberData}
                        />
                    </div>
                    {
                        data.currentActiveData.system.toLowerCase() === "retail" &&
                        <>
                            <div className="paymentContent">
                                <div className="overdueChargesContainer">
                                    <span className='overdueLabel'>Overdue Charges</span>
                                    <span className="overdueInput">
                                        <Input
                                            editIcon={ Math.floor(Number(paymentDetails?.PgsData?.odCharges)) > 0 ? true : false}
                                            style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}
                                            id="overdue-charges"
                                            value={overdueCharges ? Math.floor(overdueCharges).toLocaleString('en-IN') :"0"}
                                            error={overdueChargesError}
                                            focus ={overdueCharges === "" ? true : false}
                                            onChange={(event) => {
                                                handleChargesChange(event.target.value)
                                            }}
                                            disabled={true}
                                            editIconClass = "true"
                                            errorSpace={true}
                                        />
                                    </span>
                                </div>
                                <div className="overdueAmountContainer">
                                    <span className='overdueAmountLabel'>Overdue Amount</span>
                                    <span className="overdueAmountInput">
                                        <Input editIcon={true}
                                            style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}
                                            id="overdue-amount"
                                            value={overdueAmount ? Math.floor(overdueAmount).toLocaleString('en-IN') :"0"}
                                            error={overdueAmountError}
                                            focus ={overdueAmount === "" ? true : false}
                                            onChange={(event) => {
                                                handleAmountChange(event.target.value)
                                            }}
                                            disabled={true}
                                            editIconClass = "true"
                                            errorSpace={true}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="totalAmountContainer">
                                    <span className='totalAmountLabel'>Total Amount</span>
                                    <span className="totalAmountInput">
                                        <Input 
                                            style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}
                                            // id="overdue-charges"
                                            value={totalAmount ? Math.floor(totalAmount).toLocaleString('en-IN') : "0"}
                                            disabled={true}
                                        />
                                    </span>
                            </div>
                        </>
                    }
                    {
                        data.currentActiveData.system.toLowerCase() === "ccod" &&
                        <div className="totalAmountContainer">
                                    <span className='totalAmountLabel'>Outstanding Amount</span>
                                    <span className="totalAmountInput">
                                        <Input editIcon={Math.floor(Number(paymentDetails?.PgsData?.odAmount)) > 200 ? true : false}
                                            style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}
                                            id="outstanding-amount"
                                            value={outstandingAmount ? Math.floor(outstandingAmount).toLocaleString('en-IN') : "0"}
                                            disabled={true}
                                            focus ={ outstandingAmount === "" ? true : false}
                                            onChange={(event) => {
                                                handleOutstandingChange(event.target.value)
                                            }}
                                            editIconClass = "true"
                                            errorSpace={true}
                                            error={outstandingAmountError}
                                        />
                                    </span>
                        </div>
                    }
                    <div className="paymentOptions">
                        {
                            !iosDevice ?
                            <>
                                <div className='paymentInputRadio'>
                                    <Radio id="upi"  name="paymentRadioButton" onClick={(event) => radioCheck(event, "upi")} RadioLabel="Pay via UPI" />
                                </div>
                                <div className='paymentInputRadio'>
                                    <Radio id="others"  name="paymentRadioButton" onClick={(event) => radioCheck(event, "other")} RadioLabel="Pay via Net Banking or Debit Card"  />
                                </div>
                            </>
                            :
                                <div className='paymentInputRadio'>
                                    <Radio id="others"  name="paymentRadioButton" onClick={(event) => radioCheck(event, "other")} RadioLabel="Pay via Net Banking or Debit Card" checked />
                                </div>
                        }
                    </div>
                    <div className="mobileEmailContainer">
                                <div className="mobileContainer">
                                    <span className='mobileLabel'>Alternate Mobile Number</span>
                                    <span className="mobileInput">
                                        <Input 
                                            style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}
                                            error={mobileError}
                                            value = {alternateMobileNumber}
                                            focus ={alternateMobileNumber === "" ? true : false}
                                            onChange={(event) => {
                                                handleMobileChange(event.target.value)
                                            }}
                                            errorSpace={true}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key) || alternateMobileNumber.length >= 10) {
                                                  event.preventDefault();
                                                }
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="emailContainer">
                                    <span className='emailLabel'>Alternate Email Id</span>
                                    <span className="emailInput">
                                        <Input 
                                            style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}
                                            error={emailError}
                                            value={alternateEmailId}
                                            focus ={alternateEmailId === "" ? true : false}
                                            onChange={(event) => {
                                                handleEmailChange(event.target.value)
                                            }}
                                            errorSpace={true}
                                        />
                                    </span>
                                </div>
                            </div>
                    <div className="paymentsProceed">
                        <Button className="proceedPaymentButton" onClick={()=>makePayment()} disable={data.currentActiveData.system.toLowerCase() === "retail" ? overdueChargesError || overdueAmountError || buttonDisabled || emailError || mobileError ? true: false : outstandingAmountError || buttonDisabled || emailError || mobileError ? true : false}>
                            Proceed
                            <img src="assets/images/arrow-right.svg" alt="arrow-right" />
                        </Button>
                    </div>
                    <div className="paymentNote">
                        <p>Note: The maximum amount that may be paid via UPI is Rs  1 lac.</p>
                    </div>
                </div>
            </RadiantBorder>
        </Modal>
        </>
    )
}

export default PaymentPopup;