import React from 'react';
import './Radio.scss';
import PropTypes from 'prop-types';

const Radio = ({ id, name, RadioLabel, onClick, disabled }) => {
    return (
        <div className="radio">
            <input onClick={onClick} className='radio-button' disabled={disabled} type='radio' id={id} name={name} />
            <label className='radio-label' htmlFor={id}>{RadioLabel}</label>
        </div>
    )
}

Radio.propTypes = { 
    id : PropTypes.string , 
    name : PropTypes.string,
    RadioLabel : PropTypes.string,
    onClick : PropTypes.func
}

export default Radio;