import React, { useContext, useEffect, useState } from 'react';
import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import Modal from '../../global/Modal';
import RadiantBorder from '../../global/RadiantBorder';
import './SearchModal.scss';
import PropTypes from 'prop-types';
import SearchServiceObject from '../../services/Search';
import DataMapping from '../../context/data-mapping';
import { useNavigate } from 'react-router-dom';
import OkButtonPopUp from '../../global/OkButtonPopUp';
import FailurePopUp from '../../global/FailurePopUp';
import { STOREDATA } from '../../context/data-mapping/action/action.type';
import jsHelper from '../../utilities/jsHelper';
import AnalyticsContextProvider from '../../context/AnalyticsContext/AnalyticsContextProvider';
import OverviewServiceObject from '../../services/Overview/OverviewService';
import searchCondtions from '../../services/Search/searchCondtions';
import commonFunctionObject from '../../common-function';



const SearchModal = ({ setSearchPopup, setCalculatorModal, setWhatsAppPopUp , setPaymentPopup , setPaymentDetails , setOkButtonPopUpFlag , setOkButtonPopUpContent, setFailurePopUpModal , setFailurePopUpContent }) => {
    const [themeState] = useContext(ThemeContext);
    let [data, dispatchData] = useContext(DataMapping);
    const [searchArray, setSearchArray] = useState();
    const [searchString, setSearchString] = useState("");

    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [filterData, setfilterData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [mostSearchData, setMostSearcData] = useState([])
    const [noDataFound, setNoDataFound] = useState(false);
    const [onlyLasCustomer, setOnlyLasCustomer] = useState(false);

    const { analytics } = useContext(AnalyticsContextProvider);
    const [serviceUnavailable, setServiceUnavailable] = useState(false);
    const [serviceUnavailableContent, setServiceUnavailableContent] = useState("");

    const navigate = useNavigate();
    const onClose = (e) => {
        e.preventDefault();
        setSearchPopup(false);
    }

    const onSearchClick = (e) => {
        let accountNumber = data?.currentActiveData.accountNumber
        let gcid = data?.getUserDataV3.body.PRIMARY_GCID;


        let analyticsData = {
            event: "searchInitiate",
            data: {
                componentName: "SearchModal",
                eventName: "search-initiate"
            },
            user: {
                gcid: gcid,
                loanaccountNo: accountNumber
            },
            products: {
                "productCode": ""
            }
        }
        analytics.trackData("searchInitiate", analyticsData)

    }

    const onSearch = (event) => {

        let searchString = event.target.value;
        if (event.target.value === "") {
            setShowSearchIcon(true)
        } else {
            setSearchString(searchString);
            setShowSearchIcon(false)
            var filterData = Object.keys(searchArray).filter((value) => value.toLowerCase().includes(searchString.toLowerCase()));
            var allContracts = data?.parsedUserData;
            var currentActiveAccount = data?.['currentActiveData'].accountNumber;
            var basicDetails = data?.basicDetails;
            // var authToken = data?.authToken;
            setfilterData(filterData);
            console.log("FilterData", filterData);
            var finalData = SearchServiceObject.getFinalDataService(filterData, allContracts, basicDetails, currentActiveAccount, searchArray, data);
            console.log("Final JSx", finalData);

            // finalData.sort((a, b) => {               
            //     return b.isMostSearch === "Y" ? true : false - a.isMostSearch === "Y" ? true : false
            // })
            finalData.sort((a, b) => {
                const nameA = a.isMostSearch.toUpperCase(); // ignore upper and lowercase
                const nameB = b.isMostSearch.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            setFinalData(finalData);
            if (jsHelper.isEmptyArray(finalData)) {

                let finalResult = Object.keys(allContracts).filter((item, index) => {
                    var system = allContracts[item]?.system?.toLowerCase();
                    return system === "retail";
                });

                if (finalResult.length > 0) {
                    let data = [{
                        label: "Others",
                        conditions: {
                            system: ['retail'],
                            product: [],
                            accountStatus: [],
                            sourceSystem: [],
                            productFamily: []
                        },
                        contract: allContracts[finalResult[0]]
                    }
                    ];
                    console.log("OTherFinalData", data);
                    setFinalData(data)
                    // Show Other SR finalResult[0]
                } else {
                    setShowSearchIcon(false)
                    setNoDataFound(true);

                    console.log("NoFinalData", data);

                }
            }
            else {
                setShowSearchIcon(false)
            }
        }
    }


    useEffect(() => {
        mostSearch();
    }, [data?.currentActiveData.accountNumber]);

    useEffect(() => {
        onlyLasCheck()
    }, [])

    const searchActionPerform = (item, index) => {
        
        var stmt = item;
        var stmtLabel = item.label
        if (stmtLabel === "Calculators") {
            setSearchPopup(false);
            SearchServiceObject.calculatorRedirection(setCalculatorModal);
        } else if (stmtLabel === "WhatsApp Services") {
            SearchServiceObject.whatsAppRedirection(setWhatsAppPopUp);
            setSearchPopup(false);
        } else if (stmtLabel === "Pay your Overdue") {
            
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            SearchServiceObject.payoverdueApicall(stmt, themeState, data).then((response) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                setSearchPopup(false)
                if(response.pgsData?.ErrorMessage){
                    var payOverdueError = response.pgsData.ErrorMessage;
                }
                if(payOverdueError){
                    if(payOverdueError.toLowerCase() === "invalid company name" || payOverdueError.toLowerCase() === "invalid contract number.please enter valid contract number" || payOverdueError.toLowerCase() === "for making overdue payment towards this account please visit the sourcing app"){
                        setOkButtonPopUpContent(payOverdueError)
                        setOkButtonPopUpFlag(true);
                    }else{
                        setFailurePopUpContent({
                            apiName: "",
                            message: "We are currently unable to generate request. Please try after sometime.",
                            errorCode: "400"
                        })
                        setFailurePopUpModal(true)
                    }
                }else{
                    if(response.PgsData.totalAmount > 0){
                        setPaymentDetails(response)
                        setPaymentPopup(true)
                    }else{
                        setPaymentPopup(false)
                        setOkButtonPopUpContent("This account doesn't have any Overdue Amount.")
                        setOkButtonPopUpFlag(true);
                    }
                    
                }
                
            }).catch((error) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
    
                if (error === "163") {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We are currently unable to generate request. Please try after sometime.",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                }
                else if (error === "164") {
                    setOkButtonPopUpContent("This account doesn't have any Overdue Amount.")
                    setOkButtonPopUpFlag(true);
                }
                else {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                }
            })
        }
        else if (stmtLabel === "Pay your Overdue Amount") {

            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            SearchServiceObject.payOnlineRedirection(stmt, themeState, data).then((response) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                setSearchPopup(false)
                if(response.pgsData?.ErrorMessage){
                    var payOverdueError = response.pgsData.ErrorMessage;
                }
                if(payOverdueError){
                    if(payOverdueError.toLowerCase() === "invalid company name" || payOverdueError.toLowerCase() === "invalid contract number.please enter valid contract number" || payOverdueError.toLowerCase() === "for making overdue payment towards this account please visit the sourcing app"){
                        setOkButtonPopUpContent(payOverdueError)
                        setOkButtonPopUpFlag(true);
                    }else{
                        setFailurePopUpContent({
                            apiName: "",
                            message: "We are currently unable to generate request. Please try after sometime.",
                            errorCode: "400"
                        })
                        setFailurePopUpModal(true)
                    }
                }else{
                    if(response.PgsData.odAmount > 0){
                        setPaymentDetails(response)
                        setPaymentPopup(true)
                    }else{
                        setPaymentPopup(false)
                        setOkButtonPopUpContent("This account doesn't have any Overdue Amount.")
                        setOkButtonPopUpFlag(true);
                    }
                    
                }
                
            }).catch((error) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
    
                if (error === "163") {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We are currently unable to generate request. Please try after sometime.",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                }
                else if (error === "164") {
                    setOkButtonPopUpContent("This account doesn't have any Overdue Amount.")
                    setOkButtonPopUpFlag(true);
                }
                else {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                }
            })
        }
        else if (stmtLabel === "Bill Payment") {
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            SearchServiceObject.bbpsRedirection(themeState, data).then((finalUrl) => {
                setSearchPopup(false);

                if (finalUrl) {
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                    navigate("/bill-payment", { state: { url: { finalUrl } } })
                }
            }).catch((error) => {

                setSearchPopup(true);
                setFailurePopUpContent({
                    apiName: "get-bill-pay-link/v1",
                    message:
                        "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    errorCode: error,
                });
                setFailurePopUpModal(true);
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA,
                });
            })
        }
        else if(stmtLabel === "Online Disbursal"){
            if (data?.ODServiceUnavialble) {
                const dateAndTime = data?.dateAndTime;
                const monthNames = ["January", "February", "March", "April", "May", "June",
                  "July", "August", "September", "October", "November", "December"];
                const currentMonth = monthNames[dateAndTime.getMonth()]
                const currentYear = dateAndTime.getFullYear();
                setServiceUnavailable(true)
                setServiceUnavailableContent(`Dear Customer, Due to a planned system maintenance activity, online disbursal service is temporarily unavailable. Please try again after 9:30 a.m., 2nd ${currentMonth} ${currentYear}.`)
            } else{
                let redirectionCall = SearchServiceObject.redirectionCall(stmt, themeState, data);
                setSearchPopup(false);
                redirectionCall.then(response => {
    
                    if (response) {
                        if (response.contractObj.cdiContract) {
                            var accountNumber = response.contractObj.sapContract.CUSTOMER_NO;
                        } else if (response.contractObj.system === "las") {
                            var accountNumber = data?.getSummaryDetails?.response?.[0].LoanAccount[0].Account_Number;
                        }
                        else {
                            var accountNumber = commonFunctionObject.appendZeros(response.contractObj.CONTRACT_NUMBER);
                        }
    
    
                        let sysAccObj = {
                            accountNumber: accountNumber,
                            system: response.contractObj.system
                        }
                        dispatchData({
                            type: STOREDATA,
                            name: "currentActiveData",
                            payload: sysAccObj
                        })
    
                            navigate(response.navigateTo, { state: response.state });
                        
    
                    }
                })
            }
        }
        else {

            let redirectionCall = SearchServiceObject.redirectionCall(stmt, themeState, data);
            setSearchPopup(false);
            redirectionCall.then(response => {

                if (response) {
                    console.log("NAvvvv", response.navigateTo, { state: response.state })
                    if (response.contractObj.cdiContract) {
                        var accountNumber = response.contractObj.sapContract.CUSTOMER_NO;
                    } else if (response.contractObj.system === "las") {
                        var accountNumber = data?.getSummaryDetails?.response?.[0].LoanAccount[0].Account_Number;
                    }
                    else {
                        var accountNumber = commonFunctionObject.appendZeros(response.contractObj.CONTRACT_NUMBER);
                    }


                    let sysAccObj = {
                        accountNumber: accountNumber,
                        system: response.contractObj.system
                    }
                    dispatchData({
                        type: STOREDATA,
                        name: "currentActiveData",
                        payload: sysAccObj
                    })

                        navigate(response.navigateTo, { state: response.state });
                    

                }
            })

        }
        let accountNumber = data?.currentActiveData.accountNumber
        var gcid = data?.getUserDataV3.body.PRIMARY_GCID;


        let analyticsData = {

            "event": "internalSearch",
            "data": {
                "searchTerm": searchString, //which user enter on the search tab
                "selectedTerm": stmtLabel,  //which user selects from the suggested items 
                "componentName": "SearchModal",
                "eventName": 'internal-search'
            },
            "user": {
                "gcid": gcid,
                "loanaccountNo": accountNumber
            },
            "products": {
                "productCode": ""
            }
        }
        analytics.trackData("internalSearch", analyticsData)
    }


    const mostSearchArrayPopulate = (searchMasterObject) => {
        let retail = data?.getUserDataV3.body.contracts
        var ccodContracts = data?.getUserDataV3.body.otherSystemDetails.filter(function (element) {
            return element.SYSTEM_NAME.toLowerCase() === "ccod";
        });




        // most search condtions

        let mostSearchFilterLabel = Object.keys(searchMasterObject).filter((elem, index) => {
            if (retail.length > 0 && ccodContracts[0].ccodContractDetails.length > 0) {
                return searchMasterObject[elem].isMostSearch === "Y" && searchMasterObject[elem].SYSTEM_ID.includes("1") || searchMasterObject[elem].SYSTEM_ID.includes("3")

            } else if (retail.length > 0) {
                return searchMasterObject[elem].isMostSearch === "Y" && searchMasterObject[elem].SYSTEM_ID.includes("1")

            } else if (ccodContracts[0].ccodContractDetails.length > 0) {
                return searchMasterObject[elem].isMostSearch === "Y" && searchMasterObject[elem].SYSTEM_ID.includes("3")

            } else {
                // for las
            }

        })

        var allContracts = data?.parsedUserData;
        var currentActiveAccount = data?.['currentActiveData'].accountNumber;
        var basicDetails = data?.basicDetails;
        let mostsearchFiltereData = SearchServiceObject.getFinalDataService(mostSearchFilterLabel, allContracts, basicDetails, currentActiveAccount, searchMasterObject, data);
        setMostSearcData(mostsearchFiltereData);
        setSearchArray(searchMasterObject)

        dispatchData({
            name: "mostSearch",
            payload: mostsearchFiltereData,
            type: STOREDATA
        })

    }

    const mostSearch = (e) => {

        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })

        if (!data?.searchMasterObj) {
            let requestObject = {
                "header": {
                    "authToken": data?.authToken
                },
                "body": {}
            }

            OverviewServiceObject.mostSearchService(requestObject).then((response) => {

                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })

                // Update search master
                let searchMasterObject = {}
                response.forEach(value => {
                    searchMasterObject[value.KEYWORD] = {
                        label: value.LABEL,
                        conditions: searchCondtions[value.KEYWORD],
                        isMostSearch: value.IS_MOST_SEARCHED,
                        SYSTEM_ID: value.SYSTEM_ID ? value.SYSTEM_ID : ""
                    }
                })
                setSearchArray(searchMasterObject)
                dispatchData({
                    name: "searchMasterObj",
                    payload: searchMasterObject,
                    type: STOREDATA
                })
                mostSearchArrayPopulate(searchMasterObject)


            }).catch((error) => {
                console.log(error);
            })
        } else {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            mostSearchArrayPopulate(data?.searchMasterObj)
        }




    }

    const onlyLasCheck = () => {
        if (Object.keys(data?.basicDetails).length === 1) {
            if (data?.basicDetails[data?.currentActiveData?.accountNumber]?.lasAccountDetails) {
                setOnlyLasCustomer(true)
            }
        }
    }

    return (
        <>
            {
                serviceUnavailable ?
                    <OkButtonPopUp
                        onClick={() => setServiceUnavailable(false)}
                        visible={serviceUnavailable}
                        setModalStat={setServiceUnavailable}
                        Content={serviceUnavailableContent}
                    />
                    : null
            }

            <Modal>
                <RadiantBorder className={`searchpopup-border ${themeState}`} >
                    <div style={{ borderRadius: "28px" }}>
                        <div className='searchpopup-main-container' >
                            <div className="searchpopup-close" onClick={(e) => onClose(e)}>
                                <img src="assets/images/crossImg.svg" alt="cross image" />
                            </div>
                            <div className='searchpopup-container'>
                                <div className='search-input-box'>
                                    {
                                        showSearchIcon && <div className='search-image'>
                                            <img src="assets/images/searchPopUpIcon.svg" />
                                        </div>
                                    }

                                    <div className={`search-input-container ${!showSearchIcon ? "full-width" : ""}`} >
                                        <input className='searchpopup-input' placeholder="Search for &#x2018;Services&#x2019;" onChange={onSearch} onClick={onSearchClick} />
                                    </div>
                                </div>


                                {
                                    showSearchIcon ?

                                        <div className='search-content-container'>
                                            {
                                                data?.currentActiveData?.system.toLowerCase() !== "las" &&

                                                <p style={{
                                                    color: `${themeState === "THEME_DARK" ? "#FFFFFF" : "#000000"}`
                                                }} className='most-search-title'>Most Searched</p>}
                                            <ul className='search-list'>
                                                {
                                                    mostSearchData?.map((item, index) => {
                                                        return (
                                                            <div>
                                                                <li key={index} onClick={() => searchActionPerform(item)}>
                                                                    {item.label}
                                                                </li>
                                                            </div>
                                                        )
                                                    })
                                                }


                                            </ul>
                                        </div>

                                        :

                                        <div className='search-content-container'>
                                            <ul className='search-list'>
                                                {
                                                    finalData.length === 0 ?
                                                        <div className='noDataFound'>
                                                            <p>No Result Found</p>
                                                        </div> : finalData?.map((item, index) => {
                                                            return (
                                                                <div>
                                                                    <li key={index} onClick={() => searchActionPerform(item)}>
                                                                        <span className={` ${item.isMostSearch === "Y" && !onlyLasCustomer ? "search-highlight" : ""}`}>{item.label}</span>
                                                                    </li>
                                                                </div>
                                                            )
                                                        })
                                                }


                                            </ul>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </RadiantBorder>
            </Modal >
        </>
    )
}

SearchModal.propTypes = {
    setSearchPopup: PropTypes.func,
}

export default SearchModal