import { useRef, useState } from "react";
import "./PostLoginFooter.scss";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import getData from "./PostLoginFooterData";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { basePath } from "../../constants/constantPath";
import { useNavigate } from "react-router-dom";
import ToolsAndCalcModal from "../ToolsAndCalcModal";
import DataMapping from "../../context/data-mapping";
import OverviewServiceObject from "../../services/Overview";
import { BASIC_DETAILS, STOREDATA } from "../../context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import { theme } from "antd";
import Logout from "../Logout/Logout";
import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import commonFunctionObject from "../../common-function";
import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import WhatsAppRegisterModal from "../WhatsAppRegisterModal";
import WhatsappServiceSubscribe from "../WhatsappServiceSubscribe";
import Iframe from '../IframeBBPS';
import ReferAndEarnPopup from "../ReferAndEarnPopup";
import SearchModal from "../SearchModal";
import WealthRMDetails from "../Wealth/WealthRMDetails";
import WealthTransactionsList from "../Wealth/WealthTransactionsList/WealthTransactionsList";
import WealthServicesObject from "../../services/WealthServices/WealthServices";
import PaymentPopup from "../PaymentPopup";
import OnlineDisbursalServiceObject from "../../services/OnlineDisbursal/OnlineDisbursalService";
import OkButtonPopUp from "../../global/OkButtonPopUp";
import QrScan from "../QrScan";
import PaymentFailurePopup from "../PaymentFailurePopup";
import PaymentReceipt from "../PaymentReceipt";


const footerArray = {
  retail: [
    {
      alt: "home",
      img: "assets/images/home-icon.svg",
      childs: []
    },
    {
      alt: "search",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "customer-service",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "document-download",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "bill-pay",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "credit-score",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "more-options",
      img: "assets/images/home-icon.svg",
      childs: []

    },

  ],
  ccod: [
    {
      alt: "home",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "search",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "customer-service",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "document-download",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "bill-pay",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "credit-score",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "more-options",
      img: "assets/images/home-icon.svg",
      childs: []

    },

  ],
  las: [
    {
      alt: "home",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "search",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "document-download",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "bill-pay",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "credit-score",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "more-options",
      img: "assets/images/home-icon.svg",
      childs: []

    },

  ],
  wealth: [
    {
      alt: "Home",
      img: "assets/images/home-icon.svg",
      id: "home",
      childs: []
    },
    {
      alt: "Risk Profiling",
      img: "assets/images/home-icon.svg",
      id: "risk profiling",
      childs: []
    },
    {
      alt: "RM Details",
      img: "assets/images/home-icon.svg",
      id: "rm details",
      childs: []
    },
    {
      alt: "Transact",
      img: "assets/images/home-icon.svg",
      id: "document",
      childs: []
    },
    {
      alt: "Credit Score",
      img: "assets/images/home-icon.svg",
      id: "credit-score",
      childs: []
    },
    {
      alt: "More Options",
      img: "assets/images/home-icon.svg",
      id: "more-options",
      childs: []
    },
  ],
}

const menuArcArray = {
  retail: [
    {
      alt: "Closed Accounts",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "Tools & Calculators",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "e-locker",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "whatsapp",
      img: "assets/images/home-icon.svg",
      childs: []

    }
  ],
  ccod: [
    {
      alt: "closed-accounts",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "Tools & Calculators",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "e-locker",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "whatsapp",
      img: "assets/images/home-icon.svg",
      childs: []

    }
  ],
  las: [
    {
      alt: "closed-accounts",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "Tools & Calculators",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "e-locker",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "whatsapp",
      img: "assets/images/home-icon.svg",
      childs: []

    }
  ],
  wealth: [
    {
      alt: "download-app",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "last-5-transactions",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "order-status",
      img: "assets/images/home-icon.svg",
      childs: []

    },
    {
      alt: "whatsapp",
      img: "assets/images/home-icon.svg",
      childs: []

    }
  ],
}

const PostLoginFooter = () => {
  const navigate = useNavigate();
  const [themeState] = useContext(ThemeContext);
  const [openOptions, setOpenOptions] = useState(false);
  const [gridPopupMesage, setGridPopupfooterRefMessage] = useState(true);
  const gridPopupPhone = useRef();
  const [gridMessage, setGridMessage] = useState("");
  const [calculatorModal, setCalculatorModal] = useState(false);
  const [contextData, dispatchData] = useContext(DataMapping);
  const groupEmployeeRef = useRef();
  const { analytics } = useContext(AnalyticsContextProvider);
  const [whatsAppPopUp, setWhatsAppPopUp] = useState(false);
  const [whatsAppSuccessPopUp, setWhatsAppSuccessPopUp] = useState(false);
  const { setCustomerServicePageName, setPageName } = useContext(pageNameContext);
  const [searchPopup, setSearchPopup] = useState(false);
  // FOR DYNAMIC FOOTER ELEMENTS
  const [channelOpt, setChannelOpt] = useState("retail");
  var addingHeight = -80
  const width = window.innerWidth
  const [channelOptCon, setChannelOptCon] = useState({
    main: [],
    arc: [],
    ids: [],
    phoneFooter: [],
    phoneRow: [],
  });
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  const [footerIconArray, setFooterIconsArray] = useState(footerArray)
  const [menuArray, setMenuArray] = useState(menuArcArray)
  const [mobileArray, setMobileArray] = useState({
    retail: [],
    ccod: [],
    las: [],
    wealth: []
  })

  const [mobileDropDownArrayObject, setMobileDropDownArrayObject] = useState({})
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });
  const [mobileOptionArray, setMobileOptionArray] = useState();

  const [transactionModal, setTransactionModal] = useState(false)

  const [referandearnpopup, setreferandearnpopup] = useState(false);
  const [filteredArray, setFilteredArray] = useState([])
  const isWealthSystemActive = contextData?.currentActiveData?.system?.toLowerCase() === "wealth";
  const [paymentPopup, setPaymentPopup] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState()
  const [qrBase64, setQrBase64] = useState();
  const [qrPopupDisplay, setQrPopupDisplay] = useState(false)
  const [okButtonPopUpFlag, setOkButtonPopUpFlag] = useState(false)
  const [okButtonPopUpContent, setOkButtonPopUpContent] = useState("")
  const [paymentFailureContent, setPaymentFailureContent] = useState("");
  const [paymentFailurePopup, setPaymentFailurePopup] = useState(false);
  const [receiptData, setReceiptData] = useState()
  const [showPaymentReceipt, setShowPaymentReceipt] = useState(false);


  useEffect(() => {
    var newFooterIconObject = {
      retail: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          id: "home",
          childs: []
        },
        {
          alt: "Search",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg",
          id: "search",
          childs: []
        },
        {
          alt: "Self Service",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/customer-service-dark-icon.svg" : "assets/images/light-icon/customer-service-light-icon.svg",
          id: "self-service",
          childs: []

        },
        {
          alt: "Your Documents",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/document-center-dark-icon.svg" : "assets/images/light-icon/document-center-light-icon.svg",
          id: "document",
          childs: []
        },
        {
          alt: "Bill Pay",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg",
          id: "bill-pay",
          childs: []
        },
        // {
        //   alt: "Credit Score",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
        //   childs: []
        // },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg",
          id: "more-options",
          childs: [
            {
              alt: "Refer And Earn",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/refer-and-earn-dark-icon.svg" : "assets/images/light-icon/refer-and-earn-light-icon.svg"
            },
            {
              alt: "Tools & Calculators",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
            },
            {
              alt: "E-Locker",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
            },
            {
              alt: "Whatsapp Servicing",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-dark-icon.svg" : "assets/images/light-icon/whatsapp-light-icon.svg"
            },
            {
              alt: "Investment",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
            },
            {
              alt: "Credit Score",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
            },
          ]
        },

      ],
      ccod: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          id: "home",
          childs: []

        },
        {
          alt: "Search",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg",
          id: "search",
          childs: []
        },
        {
          alt: "Self Service",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/customer-service-dark-icon.svg" : "assets/images/light-icon/customer-service-light-icon.svg",
          id: "self-service",
          childs: []

        },
        {
          alt: "Your Documents",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/document-center-dark-icon.svg" : "assets/images/light-icon/document-center-light-icon.svg",
          id: "document",
          childs: []

        },
        {
          alt: "Bill Pay",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg",
          id: "bill-pay",
          childs: []

        },
        // {
        //   alt: "Credit Score",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
        //   childs: []

        // },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg",
          id: "more-options",
          childs: [
            {
              alt: "Refer And Earn",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/refer-and-earn-dark-icon.svg" : "assets/images/light-icon/refer-and-earn-light-icon.svg"
            },
            {
              alt: "Tools & Calculators",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
            },
            {
              alt: "E-Locker",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
            },
            {
              alt: "Whatsapp Servicing",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-dark-icon.svg" : "assets/images/light-icon/whatsapp-light-icon.svg"
            },
            {
              alt: "Investment",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
            },
            {
              alt: "Credit Score",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
            },
          ]
        },

      ],
      las: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          childs: []

        },
        {
          alt: "Search",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg",
          childs: []
        },
        {
          alt: "Bill Pay",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg",
          childs: []

        },
        {
          alt: "Holding Statement",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/las-holding-statement-dark-icon.svg" : "assets/images/light-icon/las-holding-statement-light-icon.svg",
          childs: []

        },
        {
          alt: "Credit Score",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
        },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg",
          childs: [
            {
              alt: "Tools & Calculators",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
            },
            {
              alt: "E-Locker",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
            },
            {
              alt: "Investment",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
            },
          ]
        },

      ],
      wealth: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/home-dark-icon.svg" : "assets/images/light-icon/home-light-icon.svg",
          id: "home",
          childs: []
        },
        {
          alt: "Risk Profiling",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/risk-profiling-footer-dark-icon.svg" : "assets/images/light-icon/risk-profiling-footer-light-icon.svg",
          id: "risk profiling",
          childs: []
        },
        {
          alt: "RM Details",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/rm-details-footer-dark-icon.svg" : "assets/images/light-icon/rm-details-footer-light-icon.svg",
          id: "rm details",
          childs: []

        },
        {
          alt: "Transact",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/transact-footer-dark-icon.svg" : "assets/images/light-icon/transact-footer-light-icon.svg",
          id: "document",
          childs: []
        },
        {
          alt: "Credit Score",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-dark-icon.svg" : "assets/images/light-icon/credit-light-icon.svg",
          id: "credit-score",
          childs: []
        },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/more-dark-icon.svg" : "assets/images/light-icon/more-light-icon.svg",
          id: "more-options",
          childs: [
            {
              alt: "Last 5 Transactions",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/last-5-transaction-footer-dark-icon.svg" : "assets/images/light-icon/last-5-transaction-footer-light-icon.svg"
            },
            {
              alt: "Order Status",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/order-status-footer-dark-icon.svg" : "assets/images/light-icon/order-status-footer-light-icon.svg",
              childs: []
            },
            {
              alt: "Whatsapp Servicing",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-service-dark-icon.svg" : "assets/images/light-icon/whatsapp-service-light-icon.svg"
            },
            {
              alt: "Bill Pay",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-dark-icon.svg" : "assets/images/light-icon/bill-light-icon.svg"
            },

          ]
        },
      ],
    }

    var newMobileFooterIconObject = {
      retail: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          id: "home",
          childs: []

        },
        {
          alt: "Search",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg",
          id: "search",
          childs: []
        },
        {
          alt: "Self Service",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/customer-service-dark-icon.svg" : "assets/images/light-icon/customer-service-light-icon.svg",
          id: "self-service",
          childs: []

        },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg",
          id: "more-options",
          childs: [
            {
              alt: "Refer And Earn",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/refer-and-earn-dark-icon.svg" : "assets/images/light-icon/refer-and-earn-light-icon.svg",
              id: "refer-and-earn",
              childs: []
            },
            {
              alt: "Your Documents",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/document-center-dark-icon.svg" : "assets/images/light-icon/document-center-light-icon.svg",
              id: "document",
              childs: []
            },
            {
              alt: "Bill Pay",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg",
              id: "bill-pay",
              childs: []
            },
            {
              alt: "Credit Score",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
              childs: []
            },
            {
              alt: "Tools & Calculators",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
            },
            {
              alt: "E-Locker",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
            },
            {
              alt: "Whatsapp Servicing",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-dark-icon.svg" : "assets/images/light-icon/whatsapp-light-icon.svg"
            },
            {
              alt: "Investment",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
            }
          ]
        },
      ],
      ccod: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          id: "home",
          childs: []

        },
        {
          alt: "Search",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg",
          id: "search",
          childs: []
        },
        {
          alt: "Self Service",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/customer-service-dark-icon.svg" : "assets/images/light-icon/customer-service-light-icon.svg",
          id: "self-service",
          childs: []

        },

        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg",
          id: "more-options",
          childs: [
            {
              alt: "Refer And Earn",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/refer-and-earn-dark-icon.svg" : "assets/images/light-icon/refer-and-earn-light-icon.svg",
              id: "refer-and-earn",
              childs: []
            },
            {
              alt: "Your Documents",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/document-center-dark-icon.svg" : "assets/images/light-icon/document-center-light-icon.svg",
              id: "document",
              childs: []

            },
            {
              alt: "Bill Pay",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg",
              id: "bill-pay",
              childs: []

            },
            {
              alt: "Credit Score",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
              childs: []

            },
            {
              alt: "Tools & Calculators",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
            },
            {
              alt: "E-Locker",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
            },
            {
              alt: "Whatsapp Servicing",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-dark-icon.svg" : "assets/images/light-icon/whatsapp-light-icon.svg"
            },
            {
              alt: "Investment",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
            }
          ]
        },
      ],
      las: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          childs: []

        },
        {
          alt: "Search",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg",
          id: "search",
          childs: []
        },
        {
          alt: "Bill Pay",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg",
          childs: []

        },

        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg",
          childs: [
            {
              alt: "Holding Statement",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/las-holding-statement-dark-icon.svg" : "assets/images/light-icon/las-holding-statement-light-icon.svg",
              childs: []

            },
            {
              alt: "Credit Score",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
              childs: []

            },
            {
              alt: "Tools & Calculators",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
            },
            {
              alt: "E-Locker",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
            },
            {
              alt: "Investment",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
            }
          ]
        },

      ],
      wealth: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/home-dark-icon.svg" : "assets/images/light-icon/home-light-icon.svg",
          id: "home",
          childs: []
        },
        {
          alt: "Risk Profiling",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/risk-profiling-footer-dark-icon.svg" : "assets/images/light-icon/risk-profiling-footer-light-icon.svg",
          id: "risk profiling",
          childs: []
        },
        {
          alt: "RM Details",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/rm-details-footer-dark-icon.svg" : "assets/images/light-icon/rm-details-footer-light-icon.svg",
          id: "rm details",
          childs: []

        },

        // {
        //   alt: "Credit Score",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg",
        //   childs: []
        // },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/more-dark-icon.svg" : "assets/images/light-icon/more-light-icon.svg",
          id: "more-options",
          childs: [
            {
              alt: "Transact",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/transact-footer-dark-icon.svg" : "assets/images/light-icon/transact-footer-light-icon.svg",
              id: "document",
              childs: []
            },
            {
              alt: "Credit Score",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-dark-icon.svg" : "assets/images/light-icon/credit-light-icon.svg",
              id: "credit-score",
              childs: []
            },
            {
              alt: "Last 5 Transactions",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/last-5-transaction-footer-dark-icon.svg" : "assets/images/light-icon/last-5-transaction-footer-light-icon.svg"
            },
            {
              alt: "Order Status",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/order-status-footer-dark-icon.svg" : "assets/images/light-icon/order-status-footer-light-icon.svg",
              childs: []
            },
            // {
            //   alt: "Tools & Calculators",
            //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
            // },
            // {
            //   alt: "E-Locker",
            //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
            // },
            {
              alt: "Whatsapp Servicing",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-service-dark-icon.svg" : "assets/images/light-icon/whatsapp-service-light-icon.svg"
            },
            {
              alt: "Bill Pay",
              img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-dark-icon.svg" : "assets/images/light-icon/bill-light-icon.svg"
            },

          ]
        },
      ],

    }


    let mobileOptionArray = {
      retail: [],
      ccod: [],
      las: [],
      wealth: []
    };
    Object.keys(newMobileFooterIconObject).forEach((mobileKeyName) => {
      newMobileFooterIconObject[mobileKeyName].map((ele) => {
        if (ele.alt.toLowerCase() === "more options") {
          mobileOptionArray[mobileKeyName].push(ele.childs)
        }
      })
    })
    let newObject = makeMobileDropDownArray(mobileOptionArray);
    setMobileDropDownArrayObject(newObject)
    setMobileArray(newMobileFooterIconObject)
    // setMenuArray(desktopOptionArray)
    setFooterIconsArray(newFooterIconObject)


  }, [width, themeState, contextData?.["currentActiveData"]?.system])


  useEffect(() => {
    const onBodyClick = (e) => {
      if (!groupEmployeeRef.current?.contains(e.target)) {
        setOpenOptions(false)
      }
      document.body.addEventListener('click', onBodyClick);
    }
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    }
  }, [])

  // TO rotate in Phone
  let temp = 0;
  // let temp1 = 10;
  const handleScroll = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    temp += e.deltaX;
    if (e.target !== e.currentTarget) {
      return;
    }

    // if (temp > 0 && temp < 70) {
    e.target.style.transform = `rotate(${-temp}deg)`;
    console.log("temp=" + temp);
    console.log("deltax=" + e.deltaX);
    console.log("rotation=" + e.target.style.transform);
    for (let i = 0; i < 9; i++) {
      e.target.childNodes[i].style.transform = `rotate(${temp}deg)`;
    }
    // }
  };

  const footerClickAnalytics = (menuTitle) => {
    if (isWealthSystemActive) {
      let familyMember = contextData?.basicDetails[contextData?.currentActiveData?.accountNumber]?.holdingApiRequest
      let familyHead = contextData?.parsedUserData[contextData?.currentActiveData.accountNumber]?.ORIGINAL_CLIENT_CODE
      let deviceType = commonFunctionObject.getDeviceType();
      let familyheadCode = WealthServicesObject.appendZeros(familyHead);
      let familymemberCode = WealthServicesObject.appendZeros(familyMember?.body?.clientCode);
      let analytics_data = {
        user: {
          loginMethod: "",
          loginStatus: "",
          gcid: contextData?.getUserDataV3?.body?.PRIMARY_GCID,
          loginType: "",
          loanaccountNo: "",
          firstloginDate: "",
          familyheadCode: `WEALTH${familyheadCode}`,
          familymemberCode: `WEALTH${familymemberCode}`
        },
        products: {
          productCode: contextData?.currentActiveData?.system,
        },
        data: {
          ctaText: menuTitle,
          componentName: "footer",
          bannerTitle: "",
          menuTitle: "",
          faqTitle: "",
          searchTerm: "",
          lobActivity: "",
          status: "",
          eventName: menuTitle,
          view: deviceType
        }
      }
      analytics.trackData("wealthquicklinkctaClick", analytics_data)
    } else {
      var accountNumber = contextData?.currentActiveData.accountNumber;
      var currentUserData = contextData?.parsedUserData[accountNumber];
      var gcid = currentUserData?.GCID;
      let productName = contextData?.currentActiveData?.system === "las" ? "LAS" : contextData?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
      let analytics_data = {
        user: {
          loginMethod: "",
          loginStatus: "",
          gcid: gcid,
          loginType: "",
          loanaccountNo: accountNumber,
          firstloginDate: ""
        },
        products: {
          productCode: productName,
        },
        data: {
          ctaText: "",
          componentName: "Footer",
          bannerTitle: "",
          menuTitle: menuTitle,
          faqTitle: "",
          searchTerm: "",
          lobActivity: "",
          status: "",
          eventName: menuTitle
        }
      }
      analytics.trackData("menuInteraction", analytics_data);
    }
  }

  //-------- TO Navigate to different pages based on footer icon click -------- //

  const handleFooterIconClick = (item, event) => {
    setOpenOptions(false)
    switch (item.alt) {
      case "Home":
        footerClickAnalytics("Home");
        dispatchData({
          name: "paymentDropdownChange",
          payload: false,
          type: STOREDATA
        })
        navigate("/overview")
        break;
      case "Search":
        setSearchPopup(true);
        break;
      case "More Options":
        footerClickAnalytics("More Options");
        event.stopPropagation()
        setOpenOptions(!openOptions)
        setMenuArray(item.childs)
        let chunkedMobileArray = makeMobileDropDownArray(item.childs)
        setMobileOptionArray(chunkedMobileArray)
        break;
      case "Self Service":
        footerClickAnalytics("Customer Service");
        setCustomerServicePageName('banner');
        setPageName("Self Service");
        navigate("/customer-service")
        break;
      case "Offers For You":
        footerClickAnalytics("Offers For You");
        navigate("/offers")
        break;
      case "Investment":
        footerClickAnalytics("Investment");
        navigate("/investment")
        break;
      case "Holding Statement":
        footerClickAnalytics("Holding Statement");
        dispatchData({
          name: "loaderFlag",
          payload: true,
          type: STOREDATA,
        });
        OverviewServiceObject.holdingStatementService(
          contextData.authToken,
          contextData.currentActiveData.accountNumber
        )
          .then((holdingStatementResponse) => {
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
          })
          .catch((error) => {
            setFailurePopUpContent({
              apiName: "holding-statement/v1",
              message:
                "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
              errorCode: error,
            });
            setFailurePopUpModal(true);
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
          });
        break;
      case "E-Locker":
        footerClickAnalytics("E-Locker");
        navigate("/e-locker")
        break;
      case "Tools & Calculators":
        footerClickAnalytics("Tools & Calculators");
        setCalculatorModal(true)
        break;
      case "Your Documents":
        footerClickAnalytics("Document Centre");
        if (contextData?.["currentActiveData"].system === "retail") {
          navigate("/account-details", { state: { redirectionFrom: "soa" } });
          // window.location.reload();
        } else if (contextData?.["currentActiveData"].system === "ccod") {
          navigate("/account-details", { state: { redirectionFrom: "Documents" } });
          // window.location.reload();
        }
        break;

      case "Bill Pay":
        footerClickAnalytics("Bill Pay");
        let requestObj = {
          header: {
            authToken: contextData.authToken,
            theme: themeState === "THEME_LIGHT" ? "light" : "dark"
          },
          body: {
            mobileNumber: contextData.loginMobileNo,
          },
        };
        dispatchData({
          name: "loaderFlag",
          payload: true,
          type: STOREDATA,
        });
        OverviewServiceObject.billPayService(requestObj).then((finalUrl) => {
          navigate("/bill-payment", { state: { url: { finalUrl } } })
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        }).catch((error) => {
          setFailurePopUpContent({
            apiName: "get-bill-pay-link/v1",
            message:
              "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            errorCode: error,
          });
          setFailurePopUpModal(true);
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        });
        break;
      case "Credit Score":
        footerClickAnalytics("Credit Score");
        navigate('/credit-score')
        break;
      case "Refer And Earn":
        setreferandearnpopup(true);
        break;
      case "Whatsapp Servicing":
        if (width <= 900) {
          window.open("http://wa.me/917506756060?text=Hi", "_self")
        } else {
          setWhatsAppPopUp(true);
        }
        break;
      default:
        break;
    }

  };

  const makeMobileDropDownArray = (mobileFooterObject) => {
    // var newArray = mobileFooterObject[contextData?.["currentActiveData"].system].filter((item, index) => {
    //   return index >= 2 && item.alt.toLowerCase() !== "more options"
    // })
    // console.log("new", newArray)

    var newObject = {}
    newObject[contextData?.["currentActiveData"]?.system] = sliceIntoChunks(mobileFooterObject, 4);
    return newObject
    function sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    }

  }

  console.log("check:", openOptions)

  const [wealthRMModal, setWealthRMModal] = useState(false);


  const handleFooterIconClickWealth = (item, event) => {
    setOpenOptions(false)
    switch (item.alt) {
      case "Home":
        footerClickAnalytics("Home");
        navigate("/overview")
        break;
      case "RM Details":
        footerClickAnalytics("RM Details");
        setWealthRMModal(true);
        break;
      case "Last 5 Transactions":
        footerClickAnalytics("Last 5 Transactions");
        callTransactionsApi();
        break;
      case "More Options":
        footerClickAnalytics("More Options");
        event.stopPropagation()
        setOpenOptions(!openOptions)
        setMenuArray(item.childs)
        let chunkedMobileArray = makeMobileDropDownArray(item.childs)
        setMobileOptionArray(chunkedMobileArray)
        break;
      case "Credit Score":
        footerClickAnalytics("Credit Score");
        navigate('/credit-score')
        break;
      case "Whatsapp Servicing":
        footerClickAnalytics("Whatsapp Servicing");
        if (width <= 900) {
          window.open("http://wa.me/917506596060?text=Hi", "_self")
        } else {
          setWhatsAppPopUp(true);
        }
        break;
      case "Transact":
        footerClickAnalytics("Transact");
        wealthQuicklinkRedirection("transact");
        break;
      case "Risk Profiling":
        footerClickAnalytics("Risk Profiling");
        wealthQuicklinkRedirection("riskProfiling");
        break;
      case "Order Status":
        footerClickAnalytics("Order Status");
        wealthQuicklinkRedirection("orderStatus");
        break;
      case "Bill Pay":
        footerClickAnalytics("Bill Pay");
        let requestObj = {
          header: {
            authToken: contextData.authToken,
            theme: themeState === "THEME_LIGHT" ? "light" : "dark"
          },
          body: {
            mobileNumber: contextData.basicDetails[contextData.currentActiveData.accountNumber].MOBILE_NO,
          },
        };
        dispatchData({
          name: "loaderFlag",
          payload: true,
          type: STOREDATA,
        });
        OverviewServiceObject.billPayService(requestObj).then((finalUrl) => {
          navigate("/bill-payment", { state: { url: { finalUrl } } })
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        }).catch((error) => {
          setFailurePopUpContent({
            apiName: "get-bill-pay-link/v1",
            message:
              "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            errorCode: error,
          });
          setFailurePopUpModal(true);
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        });
        break;
      default:
        break;
    }

  };

  const wealthQuicklinkRedirection = (quicklinkName) => {
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    var accountNumber = contextData?.currentActiveData?.accountNumber;
    var clientCode = contextData?.parsedUserData[accountNumber]?.CLIENT_CODE?.toLowerCase()

    let requestObj = {
      "header": {
        "authToken": contextData?.authToken,
        "requestType": quicklinkName   /* riskProfiling | transact | portfolioAnalytics  | allReports */

      },

      "body": {
        "clientCode": clientCode

      }
    }


    WealthServicesObject.wealthQuicklinkRedirection(requestObj).then(response => {
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
      window.open(response, "_self")

    }).catch(error => {
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
      setFailurePopUpContent({
        apiName: "",
        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
        errorCode: error
      })
      setFailurePopUpModal(true)
    })
  }

  const callTransactionsApi = () => {
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    let clientDetails = contextData?.basicDetails[contextData?.currentActiveData?.accountNumber]?.holdingApiRequest
    let requestObj = {
      "header": {
        "authToken": contextData?.authToken
      },
      "body": {
        "clientCode": clientDetails?.body?.clientCode,
        "clientType": clientDetails?.body?.clientType
      }
    };
    WealthServicesObject.getClientTransactionService(requestObj).then((response) => {
      let resArr = WealthServicesObject.filterLastFiveTransactions(response);
      setFilteredArray(resArr)
      setTransactionModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA,
      });
    }).catch(e => {
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA,
      });
      setFailurePopUpContent({
        apiName: "service-request/v1",
        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
        errorCode: e
      })
      setFailurePopUpModal(true)
    })

  }

  const payoverdueApicall = () => {
    var accountNumber = contextData?.currentActiveData.accountNumber
    var currentUserData = contextData?.parsedUserData[accountNumber]
    let productName = contextData?.currentActiveData?.system === "las" ? "LAS" : contextData?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);

    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: currentUserData.PRIMARY_GCID,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "Pay Overdue",
        componentName: "DashboardButtons",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        eventName: "Pay Overdue"
      }
    }
    analytics.trackData("ctaInteraction", analytics_data)
    let reqObj;
    if (contextData.currentActiveData.system.toLowerCase() === "retail") {
      reqObj = {
        "header": {
          "authToken": contextData?.authToken,
          "transactionType": "od",
        },
        "body": {
          "contractNo": accountNumber,
          "company": currentUserData.COMPANY,
        }
      }
    } else {
      reqObj = {
        "header": {
          "authToken": contextData?.authToken,
          "transactionType": "CCOD Payments"
        },
        "body": {
          "contractNo": accountNumber,
          "company": currentUserData.cdiContract.COMPANY,
        }
      }
    }
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA
    })

    OverviewServiceObject.payOverdueService(reqObj, contextData?.authToken).then((response) => {
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
      if (response.pgsData?.ErrorMessage) {
        var payOverdueError = response.pgsData.ErrorMessage;
      }
      if (payOverdueError) {
        setPaymentPopup(false)
        if (payOverdueError.toLowerCase() === "invalid company name" || payOverdueError.toLowerCase() === "invalid contract number.please enter valid contract number" || payOverdueError.toLowerCase() === "for making overdue payment towards this account please visit the sourcing app") {
          setOkButtonPopUpContent(payOverdueError)
          setOkButtonPopUpFlag(true);
        } else {
          setFailurePopUpContent({
            apiName: "",
            message: "We are currently unable to generate request. Please try after sometime.",
            errorCode: "400"
          })
          setFailurePopUpModal(true)
        }
      } else {
        if (contextData.currentActiveData.system.toLowerCase() === "retail") {
          if (response.PgsData.totalAmount > 0) {
            setPaymentDetails(response)
            setPaymentPopup(true)
          } else {
            setPaymentPopup(false)
            setOkButtonPopUpContent("This account doesn't have any Overdue Amount.")
            setOkButtonPopUpFlag(true);
          }
        } else {
          if (response.PgsData.odAmount > 0) {
            setPaymentDetails(response)
            setPaymentPopup(true)
          } else {
            setPaymentPopup(false)
            setOkButtonPopUpContent("This account doesn't have any Overdue Amount.")
            setOkButtonPopUpFlag(true);
          }
        }
      }

    }).catch((error) => {
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })

      if (error === "163") {
        setFailurePopUpContent({
          apiName: "",
          message: "We are currently unable to generate request. Please try after sometime.",
          errorCode: error
        })
        setFailurePopUpModal(true)
      }
      else if (error === "164") {
        setOkButtonPopUpContent("This account doesn't have any Overdue Amount.")
        setOkButtonPopUpFlag(true);
      }
      else {
        setFailurePopUpContent({
          apiName: "",
          message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
          errorCode: error
        })
        setFailurePopUpModal(true)
      }
    })


  }

  const callPayOnline = (amount, alternateMobileNumber, alternateEmailId) => {
    var accountNumner = contextData?.currentActiveData?.accountNumber
    var ccodData = contextData?.parsedUserData[accountNumner];
    var currentUserData = contextData?.parsedUserData[accountNumner];
    let productName = contextData?.currentActiveData?.system === "las" ? "LAS" : contextData?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
    var contractNo = ccodData.sapContract.CUSTOMER_NO;
    var gcid = ccodData.cdiContract.GCID;
    var userid = ccodData.cdiContract.USER_ID;
    var customerName = ccodData.sapContract.CUSTOMER_FIRST_NM;
    var company = ccodData.cdiContract.COMPANY;
    var companyName = "";
    (company.toUpperCase() === "TCFSL" || company.toUpperCase() === "TCL") ? companyName = "CFAB" : companyName = company.toUpperCase();

    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: currentUserData.PRIMARY_GCID,
        loginType: "",
        loanaccountNo: accountNumner,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "Pay Online",
        componentName: "DashboardButtons",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "Pay Online"
      }
    }
    analytics.trackData("ctaInteraction", analytics_data)

    var reqObj = {
      "header": {
        "authToken": contextData.authToken,
        "company": companyName

      },
      "body": {
        "contractNo": contractNo,
        "userId": userid,
        "primaryGcid": gcid,
        "customerName": customerName,
        "emailId": contextData.basicDetails[contextData.currentActiveData.accountNumber].cdiContract.EMAIL,
        "mobileNo" : contextData.basicDetails[contextData.currentActiveData.accountNumber].cdiContract.MOBILE,
        "totalAmount": amount ? amount : "",
        "alternateMobileNumber": alternateMobileNumber,
        "alternateEmailId": alternateEmailId
      }
    }

    OnlineDisbursalServiceObject.callPayOnlineAPI(reqObj).then((uniqueTransactionId) => {
    }).catch((error) => {

    })
  }

  const fetchPaymentGatewayDetails = () => {

    let requestObj = {
      "header": {
        "authToken": contextData?.authToken
      },
      "body": {
        "uniqueTransactionID": contextData?.qrDetails.uniqueTransactionID

      }
    }
    OverviewServiceObject.getPaymentGatewayDetailsService(requestObj).then((response) => {
      if (response.status.toUpperCase() === "SUCCESS") {
        setQrPopupDisplay(false)
        dispatchData({
          name: "qrDetails",
          payload: "",
          type: STOREDATA,
        });
        setReceiptData(response);
        setShowPaymentReceipt(true)
      } else if (response.status.toUpperCase() === "FAILED") {
        setQrPopupDisplay(false)
        dispatchData({
          name: "qrDetails",
          payload: "",
          type: STOREDATA,
        });
        setPaymentFailureContent("Your transaction has been failed. Please check your bank account before initiating a new transaction")
        setPaymentFailurePopup(true)
      }

    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
      {
        wealthRMModal ?
          <WealthRMDetails closeRmDetails={() => setWealthRMModal(false)} />
          : ""}
      {
        transactionModal &&
        <WealthTransactionsList setTransactionModal={setTransactionModal} filteredArray={filteredArray} />
      }
      {failurePopUpModal ? (
        <FailurePopUp
          onClick={() => setFailurePopUpModal(false)}
          visible={failurePopUpModal}
          setFailureModalStat={setFailurePopUpModal}
          failurePopUpContent={failurePopUpContent}
        />
      ) : null}
      {successPopUpModal ? (
        <SuccessPopUp
          onClick={() => setSuccessPopUpModal(false)}
          visible={successPopUpModal}
          setSuccessModalStat={setSuccessPopUpModal}
          successPopUpContent={successPopUpContent}
        />
      ) : null}

      {pathname !== "login" ? <div className="footer-space">
        {calculatorModal && (
          <ToolsAndCalcModal setCalculatorModal={setCalculatorModal} />
        )}
        {
          whatsAppPopUp && (
            <WhatsAppRegisterModal setWhatsAppPopUp={setWhatsAppPopUp} setWhatsAppSuccessPopUp={setWhatsAppSuccessPopUp} />
          )}
        {
          whatsAppSuccessPopUp && (
            <WhatsappServiceSubscribe setWhatsAppSuccessPopUp={setWhatsAppSuccessPopUp} />
          )
        }
        {
          searchPopup && (
            <SearchModal setSearchPopup={setSearchPopup} setCalculatorModal={setCalculatorModal} setWhatsAppPopUp={setWhatsAppPopUp} setPaymentPopup={setPaymentPopup} setPaymentDetails={setPaymentDetails} setOkButtonPopUpFlag={setOkButtonPopUpFlag} setOkButtonPopUpContent={setOkButtonPopUpContent} setFailurePopUpModal={setFailurePopUpModal} setFailurePopUpContent={setFailurePopUpContent} />
          )
        }
        <div className="post-login-footer">
          <div className="post-login-footer-container">
            {/* <div className='post-login-footer-menu-container'> */}
            <div className="menu-list">
              {/* TO Render Desktop Icons */}
              {width && width > 600 ?
                <>
                  {footerIconArray[contextData?.currentActiveData?.system] && footerIconArray[contextData?.currentActiveData?.system].map((item, index) => {
                    return (<div className={isWealthSystemActive ? "menu-item-wealth tooltip" : "menu-item tooltip"} onClick={(event) => { isWealthSystemActive ? handleFooterIconClickWealth(item, event) : handleFooterIconClick(item, event) }} key={index}>
                      <div>
                        <img
                          src={item.img}
                          alt={item.alt}
                          id={item.id}
                          childs={item.childs}
                        ></img>
                        <div className={`${themeState === "THEME_DARK" ? "tooltip-div THEME_DARK" : "tooltip-div"}`} >
                          <div className="tooltip-text">{item.alt}</div>
                        </div>
                      </div>
                    </div>)
                  })}
                  {openOptions && openOptions === true ?
                    // <div className="grid-popup-main">
                    <div ref={groupEmployeeRef} className="grid-popup-desktop">
                      {menuArray.map((item, index) => {
                        return (
                          <div
                            className={isWealthSystemActive ? "grid-popup-item-wealth" : "grid-popup-item"}
                            onMouseEnter={() => { setGridMessage(item.alt) }}
                            onClick={(event) => { isWealthSystemActive ? handleFooterIconClickWealth(item, event) : handleFooterIconClick(item, event) }}
                            key={index}
                          // onMouseEnter={(e) => gridPop(e)}
                          // onMouseLeave={() => gridPopOff()}
                          >
                            <img
                              src={item.img}
                              alt={item.alt}
                            ></img>
                          </div>
                        )
                      })}
                      {gridPopupMesage && (
                        <div className="grid-popup-item-message">
                          <div className="arc-message">
                            <img src={`${themeState === "THEME_DARK" ? "assets/images/curved-div-inverted.svg" : "assets/images/curved-div.svg"}`} alt="" />
                            <p>{gridMessage}</p>
                          </div>
                        </div>
                      )}
                    </div>
                    // </div> 
                    : null}
                </>
                :
                <>
                  {
                    mobileArray[contextData?.currentActiveData?.system] && mobileArray[contextData?.currentActiveData?.system].map((item, index) => {
                      // if (index < 2) {
                      return (<div className={isWealthSystemActive ? "menu-item-wealth" : "menu-item"} onClick={(event) => { isWealthSystemActive ? handleFooterIconClickWealth(item, event) : handleFooterIconClick(item, event) }} key={index}>
                        <img
                          src={item.img}
                          alt={item.alt}
                          id={item.id}
                        ></img>
                      </div>)
                      // } else if (item.alt.toLowerCase() === "more options") {
                      //   return (<div className="menu-item" onClick={(event) => { handleFooterIconClick(item.alt, event) }}>
                      //     <img
                      //       src={item.img}
                      //       alt={item.alt}
                      //       id={item.id}
                      //     ></img>
                      //   </div>)
                      // }
                    })
                  }
                  {openOptions && openOptions === true || contextData?.showOptions === true ?
                    mobileOptionArray && mobileOptionArray[contextData?.["currentActiveData"].system] && mobileOptionArray[contextData?.["currentActiveData"].system].map((itemArray, index) => {
                      return (<div ref={groupEmployeeRef} className="grid-popup-desktop" style={{ top: `calc( ${addingHeight}px * ${index + 1})` }} >
                        {itemArray.map((item, index) => {
                          return (
                            <div
                              className={isWealthSystemActive ? "grid-popup-item-wealth" : "grid-popup-item"}
                              onMouseEnter={() => { setGridMessage(item.alt) }}
                              onClick={(event) => { isWealthSystemActive ? handleFooterIconClickWealth(item, event) : handleFooterIconClick(item, event) }}

                            >
                              <img
                                src={item.img}
                                alt={item.alt}
                                id={item.id}
                              ></img>
                            </div>
                          )
                        })}
                        {index == 0 ? gridPopupMesage &&
                          <div className="grid-popup-item-message">
                            <div className="arc-message">
                              <img src={`${themeState === "THEME_DARK" ? "assets/images/curved-div-inverted.svg" : "assets/images/curved-div.svg"}`} alt="" />
                              <p>{gridMessage}</p>
                            </div>
                          </div>
                          : null}
                      </div>)

                    })
                    : null}
                </>
              }

              {/* User thumbnail for phone */}
              <div className={isWealthSystemActive ? "user-image-phone menu-item-phone-wealth" : "user-image-phone menu-item-phone"}>
                <div className="tooltip">
                  {
                    isWealthSystemActive ?

                      <img
                        src={contextData?.getUserDataV3?.body.PROFILE_PIC !== undefined ? contextData?.getUserDataV3?.body.PROFILE_PIC !== "" ? "data:image/png;base64," + contextData?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/wealth-profile-dark-icon.svg" : "assets/images/light-icon/wealth-profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/wealth-profile-dark-icon.svg" : "assets/images/light-icon/wealth-profile-light-icon.svg"}
                        alt="person"
                        onClick={() => {
                          navigate("/profile", { state: { redirectionFrom: "profile-details" } });
                          footerClickAnalytics("profile");
                        }}
                      ></img>
                      :
                      <img
                        src={contextData?.getUserDataV3?.body.PROFILE_PIC !== undefined ? contextData?.getUserDataV3?.body.PROFILE_PIC !== "" ? "data:image/png;base64," + contextData?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg"}
                        alt="person"
                        onClick={() => {
                          navigate("/profile", { state: { redirectionFrom: "profile-details" } });
                          footerClickAnalytics("profile");
                        }}
                      ></img>
                  }
                  {/* <div className={`${themeState === "THEME_DARK" ? "profile THEME_DARK" : "profile"}`}>
                    <div className="tooltip-text">Profile</div>
                  </div> */}
                </div>
              </div>
              <div className={isWealthSystemActive ? "user-image-desktop-div-wealth" : "user-image-desktop-div"}>
                <div className="tooltip">
                  {
                    isWealthSystemActive ?
                      <img
                        src={contextData?.getUserDataV3?.body.PROFILE_PIC !== undefined ? contextData?.getUserDataV3?.body.PROFILE_PIC !== "" ? "data:image/png;base64," + contextData?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/wealth-profile-dark-icon.svg" : "assets/images/light-icon/wealth-profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/wealth-profile-dark-icon.svg" : "assets/images/light-icon/wealth-profile-light-icon.svg"}
                        alt="person"
                        className="user-image-desktop"
                        onClick={() => {
                          navigate("/profile", { state: { redirectionFrom: "profile-details" } });
                          footerClickAnalytics("profile");
                        }}
                      ></img>
                      :
                      <img
                        src={contextData?.getUserDataV3?.body.PROFILE_PIC !== undefined ? contextData?.getUserDataV3?.body.PROFILE_PIC !== "" ? "data:image/png;base64," + contextData?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg"}
                        alt="person"
                        className="user-image-desktop"
                        onClick={() => {
                          navigate("/profile", { state: { redirectionFrom: "profile-details" } });
                          footerClickAnalytics("profile");
                        }}
                      ></img>
                  }

                  <div className="profile">
                    <div className="tooltip-text">Profile</div>
                  </div>
                </div>
              </div>
              <Logout />
            </div>
          </div>
        </div>
      </div> : null}

      {
        referandearnpopup ? <ReferAndEarnPopup setreferandearnpopup={setreferandearnpopup} /> : null
      }
      {
        paymentPopup ?
          <PaymentPopup setPaymentPopup={setPaymentPopup} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} payoverdueApicall={payoverdueApicall} callPayOnline={callPayOnline} setQrBase64={setQrBase64} setQrPopupDisplay={setQrPopupDisplay} setOkButtonPopUpFlag={setOkButtonPopUpFlag} setOkButtonPopUpContent={setOkButtonPopUpContent} setFailurePopUpModal={setFailurePopUpModal} setFailurePopUpContent={setFailurePopUpContent} />
          : ""
      }
      {
        qrPopupDisplay ?
          <QrScan qrBase64={qrBase64} initialTime={330} setPaymentFailureContent={setPaymentFailureContent} setPaymentFailurePopup={setPaymentFailurePopup} closeQr={() => setQrPopupDisplay(false)} fetchPaymentGatewayDetails={fetchPaymentGatewayDetails} />
          : ""
      }
      {
        paymentFailurePopup ?
          <PaymentFailurePopup
            onclick={() => { setPaymentFailurePopup(false) }}
            content={paymentFailureContent}
          />
          : null
      }
      {
        showPaymentReceipt ?
          <PaymentReceipt closeReceipt={() => setShowPaymentReceipt(false)} receiptData={receiptData} />
          : null
      }
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      {okButtonPopUpFlag ? <OkButtonPopUp
        onClick={() => setOkButtonPopUpFlag(false)}
        visible={okButtonPopUpFlag}
        setModalStat={setOkButtonPopUpFlag}
        Content={okButtonPopUpContent} /> : null}

    </>

  );
};


export default PostLoginFooter;


