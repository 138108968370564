import React, { useContext, useEffect, useState } from 'react';
import "./PaymentReceipt.scss";
import Modal from '../../global/Modal';
import RadiantBorder from '../../global/RadiantBorder';
import Button from '../../global/Button';
import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PaymentReceiptPdf from '../PaymentReceiptPdf';
import commonFunctionObject from '../../common-function';
import { useLocation, useNavigate } from 'react-router-dom';
import DataMapping from '../../context/data-mapping';

const PaymentReceipt = ({closeReceipt, receiptData}) =>{
    const navigate = useNavigate()
    const [themeState] = useContext(ThemeContext);
    const [data , dispatchData] = useContext(DataMapping)
    const [downloadReceipt, setDownloadReceipt] = useState(false); 
    const [customerName , setCustomerName] = useState("");
    const location = useLocation()
    let paymentDate = commonFunctionObject.convertUTCToIST(receiptData.modifiedDate)[0]
    let paymentTime = commonFunctionObject.convertUTCToIST(receiptData.modifiedDate)[1]


    useEffect(()=>{

        window.html2canvas = html2canvas
        const doc = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: 'a4',
        });

        doc.html(document.getElementById('PaymentReceipt'), {
        margin: [50, 10, 50, 10],
        callback: function (doc) {
            doc.save("Payment Receipt");
        },
        html2canvas: {
            useCORS: true
        },
        x: 1,
        y: 1,
        });
    },[downloadReceipt])

    const downloadAction = () =>{
        setDownloadReceipt(true)
        setTimeout(() => {
            closeReceipt()
            navigate(location.pathname)
        }, 5000);
    }
    useEffect(() =>{
        if(data.currentActiveData.system.toLowerCase() === "retail"){
            if(data.basicDetails[receiptData.contractNumber].LASTNAME != "-"){
                setCustomerName(data.basicDetails[receiptData.contractNumber].FIRSTNAME + data.basicDetails[receiptData.contractNumber].LASTNAME)
            }else{
                setCustomerName(data.basicDetails[receiptData.contractNumber].FIRSTNAME)
            }
        }else{
            setCustomerName(data.basicDetails[receiptData.contractNumber].cdiContract.CUSTOMER_NAME)
        }
    },[])
    return (
        <>
        <Modal >
                <RadiantBorder className='paymentReceiptContainer' >
                <div className={`${themeState}` } style={{borderRadius:"3rem"}}>
                    <img
                        src="assets/images/crossImg.svg"
                        alt="close-icon"
                        className="closePaymentReceipt"
                        onClick={() =>{closeReceipt() ; navigate("/overview")}}
                    />
                    <div  className="paymentReceiptInnerContainer" >
                     <p>Dear {customerName} ,<br/>
                        Your payment of Rs.{receiptData.totalAmount} has been successfully processed. The details are as given below:</p>
                        <table>
                            <tr>
                                <td><strong>Loan Number:</strong></td>
                                <td>{receiptData.contractNumber}</td>
                            </tr>
                            <tr>
                                <td><strong>Transaction Reference Number:</strong></td>
                                <td>{receiptData.tclTxnId}</td>
                            </tr>
                            <tr>
                                <td><strong>Bank Reference Number: </strong></td>
                                <td>{receiptData?.bankRefNo}</td>
                            </tr>
                            <tr>
                                <td><strong>Payment For:</strong> </td>
                                <td>{receiptData.transactionType}</td>
                            </tr>
                            <tr>
                                <td><strong>Amount (Rs.):</strong></td>
                                <td>{receiptData.totalAmount}</td>
                            </tr>
                            <tr>
                                <td><strong>Transaction Date:</strong> </td>
                                <td>{paymentDate + " " + paymentTime} </td>
                            </tr>
                        </table>
                        <p>The amount will reflect against your loan account within 2 working days.</p>
                        <p className='receiptDisclaimer'>If you have any queries or require any assistance, you can reach us on our customer care
                        number 1860 267 6060 or e-mail us at customercare@tatacapital.com, for Home Loan
                        customercare.housing@tatacapital.com</p>
                        <p className='receiptGenerationDate'>Receipt Generated Date: {commonFunctionObject.convertDateToDDMMMYYYY(new Date(commonFunctionObject.convertYYYYMMDD(paymentDate)))}</p>
                        <div className="downloadReceipt">
                            <Button onClick={()=>downloadAction() } >Download</Button>
                        </div>
                    </div>
                </div>
                </RadiantBorder>
        </Modal>
         {
            <div style={{ display: "none" }}>
              {
                 downloadReceipt ? <PaymentReceiptPdf receiptData={receiptData} customerName={customerName} /> : null
              }
            </div>
          }
        </>
    )
}

export default PaymentReceipt;