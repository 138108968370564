import React from "react";
import "./AccountNumberDropDown.scss"
import { useContext, useRef, useEffect } from "react";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import { THEME_DARK } from "../../context/ThemeContext/actions";
import DataMapping from "../../context/data-mapping";
import { STOREDATA } from "../../context/data-mapping/action/action.type";
import PropTypes from 'prop-types';
import commonFunctionObject from "../../common-function";

const AccountNumberDropDown = ({ className,
    optionArray,
    optionState,
    setOptionState,
    selectedOption,
    setSelectedOption,
    labelFlag,
    labelText,
    labelClass,
    setActiveTab,
    storeSelectAccNumberData,
    bankLas,
    closeAcc,
    setEmployementType,
    ccodAccount,
    wealthBankAccount,
    asterisk,
    callGetDisbursalDetails,
    error,
    errorSpace,
    handleDropDownChange,
    setdisabled,
    disabled,
    profilePic,
    setProfilePic
}) => {

    const [themeState] = useContext(ThemeContext);
    const dropDownRef = useRef()
    const [data, dispathData] = useContext(DataMapping)
    const handdleOptionChange = (obj) => {
        setSelectedOption(obj.value);
        console.log(data?.currentActiveData?.system)
        var curActiveAccountNumber = obj?.value?.split(" ")[0]
        if (data?.currentActiveData?.system.toLowerCase() == "ccod") {
            //ccod profile banking section logic
            if (data?.disbursalDetailsSortedArray && ccodAccount && data?.disbursalDetailsSortedArray[curActiveAccountNumber] && data?.filterarraychangeDropdown) {
                //click on same account number
                dispathData({
                    name: "clickaccountNumber",
                    payload: !data?.clickaccountNumber,
                    type: STOREDATA
                });
            }
        }
        else if (data?.currentActiveData?.system.toLowerCase() == "wealth") {
            if (data?.filterarraychangeDropdown && wealthBankAccount) {
                dispathData({
                    name: "clickaccountNumber",
                    payload: !data?.clickaccountNumber,
                    type: STOREDATA
                });
            }
        }

        if (setActiveTab) {
            setActiveTab(obj.id);
        }
        if (storeSelectAccNumberData) {
            storeSelectAccNumberData(obj.value)
        }
        if (setEmployementType) {
            setEmployementType(obj.value.split(" ")[0])
        }
        if (callGetDisbursalDetails) {
            callGetDisbursalDetails(obj.id)
        }
        if (handleDropDownChange) {
            handleDropDownChange(obj.value.split(" ")[0])
        }
        let newActiveObject = {
            accountNumber: obj.value.split(" ")[0],
            system: obj.system,
        }
        dispathData({
            type: STOREDATA,
            name: "currentActiveData",
            payload: newActiveObject,
        })
        let currentMobileNo
        let currentEmailId
        if (!data?.currentActiveData?.system.toLowerCase() === "wealth") {
            currentMobileNo = data?.basicDetails[obj.value.split(" ")[0]].system.toLowerCase() === "retail" ? commonFunctionObject.getFirstValue(data.basicDetails[obj.value.split(" ")[0]].MOBILENO) === "" ? "-" : commonFunctionObject.getFirstValue(data.basicDetails[obj.value.split(" ")[0]].MOBILENO) : commonFunctionObject.getFirstValue(data.basicDetails[obj.value.split(" ")[0]].cdiContract.MOBILE) === "" ? "-" : commonFunctionObject.getFirstValue(data.basicDetails[obj.value.split(" ")[0]].cdiContract.MOBILE);
            currentEmailId = data?.basicDetails[obj.value.split(" ")[0]].system.toLowerCase() === "retail" ? commonFunctionObject.getFirstValue(data.basicDetails[obj.value.split(" ")[0]].EMAIL) === "" ? "-" : commonFunctionObject.getFirstValue(data.basicDetails[obj.value.split(" ")[0]].EMAIL) : commonFunctionObject.getFirstValue(data.basicDetails[obj.value.split(" ")[0]].cdiContract.EMAIL) === "" ? "-" : commonFunctionObject.getFirstValue(data.basicDetails[obj.value.split(" ")[0]].cdiContract.EMAIL);

        }
        if ((currentMobileNo === "-") || (currentEmailId === "-")) {
            setdisabled(true)
        } else {
            if (disabled) {
                setdisabled(false);
            }
        }

        //profile section customer Name changes based on system
        let getUserDataResponse = data?.getUserDataV3?.body
        let activeCustomerName = commonFunctionObject.currentActiveCustomerName(obj?.system, getUserDataResponse);
        if (activeCustomerName) {
            dispathData({
                type: STOREDATA,
                name: "customerName",
                payload: activeCustomerName
            });
        }

        //profile section profile image changes based on system(PROFILE_PIC undefined condition handled )
        if (profilePic !== "" && profilePic !== undefined) {  //check for click on accountnumber dropdown from profile page
            if (obj?.system?.toLowerCase() === "wealth") {
                var getProfilePic = data?.getUserDataV3?.body?.PROFILE_PIC !== undefined ? data?.getUserDataV3?.body?.PROFILE_PIC !== "" ? data?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/wealth-profile-dark-icon.svg" : "assets/images/light-icon/wealth-profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/wealth-profile-dark-icon.svg" : "assets/images/light-icon/wealth-profile-light-icon.svg"
                setProfilePic(getProfilePic)
            } else {
                var getProfilePic = data?.getUserDataV3?.body?.PROFILE_PIC !== undefined ? data?.getUserDataV3?.body?.PROFILE_PIC !== "" ? data?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg"
                setProfilePic(getProfilePic)
            }
        }
    };

    useEffect(() => {

        const onBodyClick = (e) => {
            if (!dropDownRef.current?.contains(e.target)) {
                setOptionState(false)
            }
        }
        document.body.addEventListener('click', onBodyClick);
        return () => {
            document.body.removeEventListener('click', onBodyClick);
        }
    }, [])

    return (
        <>
            <div
                className={`account-drop-down-account-number ${className ? className : ""
                    }`}
            >
                {labelFlag ? (
                    <label htmlFor="account-drop-down-account-details" className={labelClass ? labelClass : ""}>{labelText}{asterisk ? <span className="red-star">*</span> : ""}</label>
                ) : null}

                <div
                    className="select-wrapper "
                    onClick={(event) => {
                        event.stopPropagation()
                        setOptionState(!optionState);
                    }}
                >
                    <p className="accountNumber">{selectedOption}</p>
                    <img
                        style={{
                            width: "1.3rem",
                            filter: `${themeState === THEME_DARK ? "invert(1)" : ""}`,
                            transform: `${optionState ? "rotate(180deg)" : ""}`,
                        }}

                        src="assets/images/arrow-down.svg"
                        alt=''
                    />
                    {optionState ? (
                        <div ref={dropDownRef} className={`account-drop-down-options-container  ${ccodAccount ? "ccod-account-number" : ""}`}>
                            <div className={data?.currentActiveData?.system.toLowerCase() === "wealth" ? "dropdown-inner-container-wealth" : "dropdown-inner-container"}>
                                {Object.keys(optionArray).map((keyName, index) => {
                                    return (<div key={index}>
                                        {
                                            bankLas && closeAcc ?
                                                keyName !== "LAS" && keyName !== "Closed Loans" ?
                                                    optionArray[keyName].length > 0 ?
                                                        <>
                                                            <p className="activityName">{keyName}</p>
                                                            {optionArray[keyName].map((obj, index) => {
                                                                return (
                                                                    <p className="accountNumber" key={index} onClick={() => {
                                                                        handdleOptionChange(obj)
                                                                    }}>
                                                                        {obj.value}
                                                                    </p>
                                                                )
                                                            })}
                                                        </>
                                                        : null
                                                    : null
                                                : bankLas ?
                                                    keyName !== "LAS" ?
                                                        optionArray[keyName].length > 0 ?
                                                            <>
                                                                <p className="activityName">{keyName}</p>
                                                                {optionArray[keyName].map((obj, index) => {
                                                                    return (
                                                                        <p className="accountNumber" key={index} onClick={() => {
                                                                            handdleOptionChange(obj)
                                                                        }}>
                                                                            {obj.value}
                                                                        </p>
                                                                    )
                                                                })}
                                                            </>
                                                            : null
                                                        : null
                                                    :
                                                    optionArray[keyName].length > 0 ?
                                                        <>
                                                            <p className="activityName">{keyName}</p>
                                                            {optionArray[keyName].map((obj, index) => {
                                                                return (
                                                                    <p className="accountNumber" key={index} onClick={() => {
                                                                        handdleOptionChange(obj)
                                                                    }}>
                                                                        {obj.value}
                                                                    </p>
                                                                )
                                                            })}
                                                        </>
                                                        : null


                                        }

                                    </div>)
                                })}
                            </div>
                            {/* {!bankLas ? optionArray?.map((obj, index) => (
                                <p key={index} onClick={() => {
                                    handdleOptionChange(obj)
                                }}>
                                    {obj.value}
                                </p>
                            )) : optionArray.filter(obj => obj.system !== "las").map((obj, index) => (
                                <p key={index} onClick={() => {
                                    handdleOptionChange(obj)
                                }}>
                                    {obj.value}
                                </p>
                            ))} */}
                        </div>
                    ) : null}

                </div>

            </div>
            {
                errorSpace && (
                    <div className="error-span">
                        <span>{error}</span>
                    </div>
                )
            }
        </>

    );
}

AccountNumberDropDown.propTypes = {
    className: PropTypes.string,
    optionArray: PropTypes.any,
    optionState: PropTypes.bool,
    setOptionState: PropTypes.func,
    selectedOption: PropTypes.string,
    setSelectedOption: PropTypes.func,
    labelFlag: PropTypes.bool,
    labelText: PropTypes.string,
    labelClass: PropTypes.string,
    setActiveTab: PropTypes.func,
    storeSelectAccNumberData: PropTypes.func,
    bankLas: PropTypes.bool,
    closeAcc: PropTypes.bool,
    setEmployementType: PropTypes.func,
    ccodAccount: PropTypes.bool,
    asterisk: PropTypes.bool,
    callGetDisbursalDetails: PropTypes.func,
    error: PropTypes.string,
    errorSpace: PropTypes.bool,
    handleDropDownChange: PropTypes.func,
    profilePic: PropTypes.string,
    setProfilePic: PropTypes.func,
    wealthBankAccount : PropTypes.bool
}

export default AccountNumberDropDown